export default {
  refreshOrder(context, data){
    Rails.ajax({
      beforeSend: () => true,
      url: '/vue_ajax/refresh_order',
      type: "get",
      data: data,
      dataType: 'json',
      contentType: 'application/json',
      success: function(response) {
        context.commit('updateOrder', response);
      },
      error: function(response) {
        notification(I18n.t("error"));
      }
    })
  }
}
