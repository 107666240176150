<template>
  <div class="row no-gutters">
    <div class="col-12 col-lg-4 form-group"
      v-on:mouseenter="mouseenter"
      v-on:mouseleave="mouseleave">
      <input
        id="hdpkr2"
        type="text"
        :placeholder="placeholder"
        class="calendar-input"
        autocomplete="off"
        style="cursor:pointer;">
      <i class="icon_calendar"></i>
    </div>
    <div class="room-col col-12 col-lg-3">
      <div class="styled-select">
        <select v-model="roomCount" class="select-rooms centralize">
          <option :value="0" disabled> {{ $i18n("houses.show.select_room") }}</option>
          <option v-for="room of maxRooms" :value="room" :key="room">
          {{ displayFormattedRoom(room) }}
          </option>
        </select>
      </div>
    </div>
    <div class="room-col col-12 col-lg-3">
      <div class="styled-select">
        <select v-model="guestCount" class="select-rooms centralize">
          <option :value="0" disabled> {{ $i18n("houses.show.select_guest") }}</option>
          <option v-for="guest of maxGuests" :value="guest" :key="guest">
          {{ displayFormattedGuest(guest) }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-lg-2">
      <p class="search_button" @click="search" :class="isEnabled ? '' : 'disabled'">{{$i18n('houses.show.search')}}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HotelDatepicker from 'hotel-datepicker'

export default {
  data: function() {
    return {
      dirty: false,
      hdpkr: null,
      checkinDate: this.$store.state.startDate,
      checkoutDate: this.$store.state.endDate,
      roomCount: this.$store.state.rooms || 0,
      guestCount: this.$store.state.guests || 0,
      maxBookingWindowDays: this.$store.state.maxBookingWindowDays,
      separator: ' -> ',
      placeholder: I18n.t('houses.dates_picker.check-in') + ' -> ' + I18n.t('houses.dates_picker.check-out')
    }
  },
  computed: {
    calendarOptions: function() {
      return {
        moveBothMonths: true,
        autoClose: true,
        startDate: new Date(),
        endDate: moment().add(this.maxBookingWindowDays, 'days').toDate(),
        enableCheckout: true,
        separator: this.separator,
        onSelectRange: this.updateDateRange,
        i18n: window.I18n.t('houses.hotel_datepicker'),
        showTopbar: false,
        animationSpeed: ".2s"
      }
    },
    ...mapGetters([
      'maxRooms',
      'maxGuests'
    ]),
    checkInText: function() {
      if (this.$store.state.startDate) {
        return moment(this.$store.state.startDate).format('YYYY-MM-DD')
      } else {
        return I18n.t('houses.dates_picker.select_dates')
      }
    },
    checkOutText: function() {
      if (this.$store.state.endDate) {
        return moment(this.$store.state.endDate).format('YYYY-MM-DD')
      } else {
        return ''
      }
    },
    isEnabled() {
      return this.isFieldsPresent()
    }
  },
  methods: {
    isFieldsPresent() {
      // check if room is selected, and dates are selected or preloaded
      return this.roomCount > 0 && (this.dirty || (!!this.$store.state.startDate && !!this.$store.state.endDate))
    },
    search() {
      if (!this.isFieldsPresent()) {return};
      let URL = `${window.location.origin}${this.$store.state.housePath}?start_date=${moment(this.checkinDate).format('YYYY-MM-DD')}&end_date=${moment(this.checkoutDate).format('YYYY-MM-DD')}&rooms=${this.roomCount}`
      if (this.guestCount > 0) { // optional guest number querystring
      URL = `${window.location.origin}${this.$store.state.housePath}?start_date=${moment(this.checkinDate).format('YYYY-MM-DD')}&end_date=${moment(this.checkoutDate).format('YYYY-MM-DD')}&rooms=${this.roomCount}&guests=${this.guestCount}`
      }
      Turbolinks.visit(URL);
    },
    displayFormattedRoom: function(count) {
      return I18n.t("houses.show.rooms", {count})
    },
    displayFormattedGuest: function(count) {
      return I18n.t("houses.show.guests", {count})
    },
    updateDateRange: function() {
      this.dirty = true;
      this.checkinDate = this.hdpkr2.getValue().split(this.separator)[0]
      this.checkoutDate = this.hdpkr2.getValue().split(this.separator)[1]
    },
    setDatepPickerDefaultValue: function() {
      if (this.checkInText && this.checkOutText) {
        this.hdpkr2.setValue(this.checkInText + this.separator + this.checkOutText)
      }
    },
    mouseenter: function(){
      this.setDatepicker();
      this.hdpkr2.open();
    },
    mouseleave: function(){
      this.hdpkr2.close();
    },
    setDatepicker() {
      if (this.hdpkr2) {this.hdpkr2.destroy();}
      this.hdpkr2 = new HotelDatepicker(document.getElementById('hdpkr2'), this.calendarOptions)
      this.setDatepPickerDefaultValue()
    },
  },
  mounted() {
    if (!!this.$store.state.startDate &&
        !!this.$store.state.endDate &&
        !!this.$store.state.rooms) {
      this.$scrollTo('#view-on-map');
    }
    this.setDatepicker();
    if (this.roomCount == 0) {
      // pre-select 1 room if there are rooms available, and there are no roomcount querystrings
      this.roomCount = this.$store.getters.maxRooms > 0 ? 1 : 0
    }
    if (this.guestCount == 0) {
      // pre-select 1 guest if there are guests available, and there are no guestcount querystrings
      this.guestCount = this.$store.getters.maxGuests > 0 ? 1 : 0
    }
  },
}
</script>

<style lang="scss">
// global css
@import 'hotel-datepicker/dist/css/hotel-datepicker.css';
#hdpkr2 {
  color: var(--gray);
  width: 98%;
  border-radius: 2px;
}
#datepicker-hdpkr2 {
  line-height: 19px;
  @media (min-width: 480px) {
    width: 560px !important;
  }
  &.datepicker__months {
    &:before {
      background: var(--lighter-gray);
    }
    @media (max-width: 645px) {
      #month-2-hdpkr {
        display:none;
      }
    }
  }
  .datepicker__month-day--first-day-selected, .datepicker__month-day--last-day-selected {
    background-color: var(--primary) !important;
    color: #fff;
  }
  .datepicker__month-day--selected, .datepicker__month-day--hovering {
    background-color: rgba(var(--primary-text), 0.5);
    color: #fff;
  }
  .datepicker__month-button:hover {
    background-color: var(--secondary) !important;
  }
}
</style>
<style lang="scss" scoped>
// local css
.search_button{
  border-radius: 3px;
  padding-top: 15px !important;
  right: 0 !important;
  margin: 0 !important;
  transition: all 0.3s ease-in-out;
  color: var(--white);
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  border: 0;
  height: 50px;
  cursor: pointer;
  outline: none;
  width: 100.5% !important;
  background-color: var(--primary);
  &:hover {
    background-color: var(--secondary);
  }
  @media (max-width: 991px) {
    margin: 20px 0 0 0 !important;
  }
}
.disabled {
  background-color: var(--light-gray) !important;
  cursor: default !important;
  &:hover {
    color: var(--white);
  }
}
@media (max-width: 991px) {
  .styled-select {
    margin-bottom: 5px !important;
    padding-left: 45% !important;
    width: 100%;
    select {
      padding-left: 0 !important;
    }
  }
}
</style>
