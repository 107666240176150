<template>
  <!-- Sign In Popup -->
  <div id="sign-in-dialog" class="zoom-anim-dialog mfp-hide sign_in_dialog" @click="clearError">
    <div class="small-dialog-header small_dialog_header">
      <h3>{{ $i18n("login") }}</h3>
    </div>
    <div class="sign-in-wrapper">
      <div v-show="hasLoginError" class="ajax-error">{{ $i18n("invalid_login") }}</div>
      <form autocomplete="off">
        <div class="form-group">
          <label>{{ $i18n("email") }}</label>
          <input type="email" class="form-control" name="email" v-model="email">
          <i class="icon_mail_alt sign_in_icon"></i>
        </div>
        <div class="form-group">
          <label>{{ $i18n("password") }}</label>
          <input type="password" class="form-control" name="password" value="" v-model="password">
          <i class="icon_lock_alt sign_in_icon"></i>
        </div>
        <div class="clearfix add_bottom_15">
          <div class="checkboxes float-left">
            <label id="remember_me" class="container_check">{{ $i18n("remember_me") }}
              <input type="checkbox" v-model="rememberMe">
              <span class="checkmark bg-primary"></span>
            </label>
          </div>
          <div class="float-right mt-1"><a id="forgot" href="javascript:void(0);" @click="resetPassword">{{ $i18n("forgot_password") }}</a></div>
        </div>
        <div class="text-center"><p class="btn_1 full-width ajax-button" :class="{ loading }" @click="signIn">{{ $i18n('login') }}</p></div>
        <div class="text-center">
          {{ $i18n("dont_have_an_account") }} <span class="sign_up" @click="signUp">{{ $i18n("signup") }}</span>
        </div>
      </form>
    </div>
  </div>
  <!-- /Sign In Popup -->
</template>

<script>
export default {
  data: function() {
    return {
      email: "",
      password: "",
      rememberMe: true,
      error: false,
      loading: false
    }
  },
  computed: {
    hasLoginError() {
      return this.error;
    }
  },
  methods: {
    clearError() {
      this.error = false;
    },
    signIn() {
      var self = this;
      self.loading = true;
      Rails.ajax({
        beforeSend: () => true,
        url: '/users/sign_in',
        type: "POST",
        data: this.$json_to_params({email: this.email,
                                    password: this.password,
                                    remember_me: this.rememberMe}),
        dataType: 'json',
        contentType: 'application/json',
        success: function(response) {
          sessionStorage.setItem('reloadPageAfterAjaxLogIn', 'true');
          location.reload();
        },
        error: function(response) {
          self.error = true;
        },
        complete() {
          self.loading = false;
        }
      })
    },
    signUp() {
      $.magnificPopup.open({
        items: {
          src: '#register-dialog',
        }
      });
    },
    resetPassword() {
      $.magnificPopup.open({
        items: {
          src: '#reset-password-dialog',
        }
      });
    }
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
#remember_me, #forgot {
  &:hover {
    color: var(--primary);
    transition: all 0.15s ease-in-out;
  }
}
.sign_in_icon {
  top: 30px !important;
}
.small_dialog_header {
  margin-bottom: 20px !important;
}
.sign_in_dialog {
  padding-bottom: 20px !important;
}
.sign_up {
  color: var(--primary);
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
