<template>
  <div v-show="!!this.address" style="margin: 25px;">
    <h3>{{ $i18n('houses.show.location') }}</h3>
    <div class="map map_single map-container map-basic">
      <iframe
        class="map-top"
        width="598"
        height="450"
        frameborder="0"
        style="border:none;"
        :src="mapPath"
        allowfullscreen="">
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: String,
    api_key: String,
    place_id: String,
    language: String,
    coords: String
  },
  data: function() {
    return {
    }
  },
  computed: {
    mapPath() {
      const location = this.place_id ? `place_id:${this.place_id}` : this.coords
      return `https://www.google.com/maps/embed/v1/place?key=${this.api_key}&q=${location}&zoom=13&language=${this.language}`
    }
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
</style>
