import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';

import initialState from './model';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

Vue.use(Vuex);

export default new Vuex.Store({
  state: new initialState(),
  actions,
  getters,
  mutations,
});