/* eslint no-console:0 */
/* jshint esversion: 6 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue/dist/vue.esm';
// Global Plugins
import TurbolinksAdapter from 'vue-turbolinks';
import AirhostPlugin from '../airhost_plugin';
import VueCurrencyFilter from 'vue-currency-filter';
import VueScrollTo from 'vue-scrollto';
// Mixins
import BackgroundServicesPack from './background_services';
import HouseIndexPack from './house_index';
import HouseShowPack from './house_show';
import CheckoutPack from './checkout';
import CheckoutPaymentPack from './checkout_payment';
import CheckoutAddressPack from './checkout_address';
// Vuex Stores
import HouseShowStore from "../store/house_show/store";
import HouseIndexStore from "../store/house_index/store";
import CheckoutStore from "../store/checkout/store";

// Global Configs
window.Vue = Vue;
Vue.use(TurbolinksAdapter);
Vue.use(VueCurrencyFilter, $airhost_settings.currency_setting);
Vue.use(VueScrollTo);
Vue.use(AirhostPlugin);

// CSS variables
import '../assets/css/theme.css';
// Color mapping for primary color
var color_mapping = {
  "aqua": {"primary":"0, 128, 128", "secondary":"255, 127, 80"},
  "green": {"primary":"0, 100, 0", "secondary":"205, 92, 92"},
  "orange": {"primary":"255, 127, 80", "secondary":"106, 90, 205"},
  "blue": {"primary":"0, 123, 255", "secondary":"255, 127, 80"},
  "beige": {"primary":"160, 82, 45", "secondary":"70, 130, 180"},
  "gray": {"primary":"112, 128, 144", "secondary":"105, 105, 105"},
  "navy": {"primary":"106, 90, 205", "secondary":"255,140,80"},
  "peach": {"primary":"1219, 112, 147", "secondary":"46,139,87"},
  "purple": {"primary":"128, 0, 128", "secondary":"207,181,59 "},
  "red": {"primary":"178, 34, 34", "secondary":"46,139,87"},
  "violet": {"primary":"138, 43, 226", "secondary":"212, 175, 55"}
}
if ($airhost_settings.color !== 'default') {
  document.querySelector(':root').style.setProperty('--primary', `rgb(${color_mapping[$airhost_settings.color].primary})`);
  document.querySelector(':root').style.setProperty('--secondary', `rgb(${color_mapping[$airhost_settings.color].secondary})`);
  document.querySelector(':root').style.setProperty('--primary-text', color_mapping[$airhost_settings.color].primary);
  document.querySelector(':root').style.setProperty('--secondary-text', color_mapping[$airhost_settings.color].secondary);
}

window.scrollPosition = {x: 0, y: 0} // Our 'old' scroll position
window.lastHref = null // Our 'old' page href

// Before visit, simply store scroll position & url/href
document.addEventListener('turbolinks:before-visit', function () {
  window.scrollPosition = {x: window.scrollX, y: window.scrollY}
  window.lastHref = window.location.href
}, false)

document.addEventListener('turbolinks:load', function() {
  // If we have a scroll position AND we're on the same page
  if (window.scrollPosition && window.lastHref !== null && (this.location.href.split('?')[0] == window.lastHref.split('?')[0])) {
    // Scroll to our previous position
    window.scrollTo(window.scrollPosition.x, window.scrollPosition.y)
  }

  // Always load on every page
  new Vue({ mixins: [BackgroundServicesPack]}).$mount('#background_services');

  // Conditional loads based on current path
  // console.log("current page is ", document.body.dataset.page + '#' + document.body.dataset.view)
  if(isCurrentPath('houses#index')){
    new Vue({ mixins: [HouseIndexPack], store: HouseIndexStore }).$mount('#house_index');
  }
  if(isCurrentPath('houses#show')){
    new Vue({ mixins: [HouseShowPack], store: HouseShowStore }).$mount('#house_show');
  }
  if(isCurrentPath('airhost/checkout#address')){
    new Vue({ mixins: [CheckoutPack, CheckoutAddressPack], store: CheckoutStore }).$mount('#checkout_address');
  }
  if(isCurrentPath('airhost/checkout#payment')){
    new Vue({ mixins: [CheckoutPack, CheckoutPaymentPack], store: CheckoutStore }).$mount('#checkout_payment');
  }
});