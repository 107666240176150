import { initialState } from 'store/house_show/model';
import OrderSummary from '../components/house_show/order_summary';
import HotelRoom from '../components/house_show/hotel_room';
import GoogleMap from '../components/house_show/google_map';
import HouseFacilities from '../components/house_show/facilities_popup';

export default {
  components: { HotelRoom, OrderSummary, GoogleMap, HouseFacilities },
  data: function() {
    return {}
  },
  computed: {
    rooms: function(){
      return this.$store.state.rooms
    }
  },
  methods: {},
  mounted: function() {
    $('a[href="#booking"]').on('click', function (e) {
      e.preventDefault();
      var target = this.hash;
      var $target = $(target);
      $('html, body').stop().animate({
        'scrollTop': $target.offset().top - 240
      }, 800, 'swing', function () {
        window.location.hash = target;
      });
    });

    var initializeGallery = function() {
      // Image popups
      $('.magnific-gallery').each(function () {
        $(this).magnificPopup({
          delegate: 'a',
          type: 'image',
          preloader: true,
          gallery: {
            enabled: true
          },
          removalDelay: 500, //delay removal by X to allow out-animation
          callbacks: {
            beforeOpen: function () {
              // just a hack that adds mfp-anim class to markup
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
              this.st.mainClass = this.st.el.attr('data-effect');
            }
          },
          closeOnContentClick: true,
          midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
        });
      });
    }
    initializeGallery()
  },
  beforeMount: function(){
    if (this.$params('modify') == 'true') {
      Turbolinks.visit(sessionStorage.getItem('showPageURL'), { action: "replace" })
    }
    // clear vuex cache
    this.$store.replaceState(new initialState());
    // map other data to vuex store. order must be the same as show page
    let [startDate, endDate, houseCurrency, ...other_keys] = Object.keys($airhost_data);
    for (let key of other_keys) {
      this.$store.state[key] = $airhost_data[key];
    }
    // set currency of order
    this.$store.dispatch("setOrderCurrency", $airhost_data[houseCurrency])
    // room sort
    this.$store.dispatch("initializeRoomSort")
    // initialize dates
    this.$store.dispatch("initializeDates", {starts_at: $airhost_data[startDate], ends_at: $airhost_data[endDate]})
    // initialize room by roomId
    this.$store.dispatch("initializeGivenRoom")
  }
}
