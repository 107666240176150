export default {
  maxRooms: (state) => {
    // if (state.houses.length > 0 && !!state.start_date && !!state.end_date) {
    //   let result = []
    //   state.houses.forEach( house => {
    //     result.push(state.maxRoomsAvailabileByHouse[house.id])
    //   })
    //   return Math.max(...result)
    // } else {
      return 10;
    // }
  },
  maxGuests: state => {
    // if (state.houses.length > 0 && !!state.start_date && !!state.end_date) {
    //   let result = []
    //   state.houses.forEach( house => {
    //   })
    //   return Math.max(...result)
    // } else {
      return 20;
    // }
  },
  maxRoomsAvailabileByHouse: (state) => (house_id) => {
    ///////////////////////////////////
    // state.houses contains all the data required for each house, grouped by houses
    // filter the data to find the data for this house, then get the room_mapping_datum
    // within room_mapping_datum, get the price_availability
    //
    // map out the total allotments for all the rooms in the house, by day
    // e.g. {day1: 3, day2: 4, ...}
    //
    // return the lowest allotments in the date range
    // e.g. 3
    ///////////////////////////////////

    let house = state.houses.find((house)=>house.id == house_id)
    let room_mapping_datum = house.room_mapping_datum
    let allotmentsForAllRooms = []

    // if rate plan is all closed, return allotment 0
    if (house.allotments && house.allotments.every(allotment => allotment && allotment['closed'])) { return 0 }
    // if date selects and one day has not any allotments, return allotment 0
    if (state.startDate && state.endDate && house.allotments && house.allotments.some(allotment => allotment.total_rooms <= 0 || allotment.closed)) { return 0 }

    // map allotments for all rooms
    Object.keys(room_mapping_datum).forEach(roomId => {
      allotmentsForAllRooms.push(room_mapping_datum[roomId].allotment)
    })

    return allotmentsForAllRooms.reduce((a,b) => a + b, 0)
  },
  maxGuestsAvailabileByHouse: (state) => (house_id) => {
    ///////////////////////////////////
    // state.houses contains all the data required for each house, grouped by houses
    // filter the data to find the data for this house, then get the room_mapping_datum
    // within room_mapping_datum, get the price_availability
    //
    // with price availability, create a map for each day, consisting of room.accomodates * allotments
    // e.g. allotments for room1 is 2. accomodates for room1 is 2. allotments for room2 is 2. accomodates for room2 is 3.
    //      push into the array [2, 2, 3, 3] for day 1.
    //      allotments for room1 is 1. accomodates for room1 is 2. allotments for room2 is 1. accomodates for room2 is 3.
    //      push into the array [2, 3] for day 2.
    //
    // with the final array, sort by biggest numnbers first.
    // state.rooms contains the room count required specified by the user
    // if 2 rooms are required, splice the top 2 biggest numbers from the array, and add them up for the entire date range
    // e.g [6, 5]
    //
    // return the lowest accommodates for the date range
    // e.g. 5
    ///////////////////////////////////

    let house = state.houses.find((house)=>house.id == house_id)
    let room_mapping_datum = house.room_mapping_datum
    let sortArray = []

    Object.keys(room_mapping_datum).forEach(roomId => {
      let data = room_mapping_datum[roomId]
      // map accommodates for all rooms
      for (var i=0; i < data.allotment; i++){
        sortArray.push(data.accommodates)
      }
    })

    // sort by highest number first
    sortArray.sort((a, b) => b-a)
    if (state.rooms == '') {
      return sortArray.reduce((a,b) => a + b, 0)
    } else {
      // slice by number of rooms required
      return sortArray.slice(0, state.rooms).reduce((a,b) => a + b, 0)
    }
  },
};
