<template>
  <div
    v-on:mouseenter="mouseenter"
    v-on:mouseleave="mouseleave">
    <input
      id="hdpkr"
      type="text"
      :placeholder="placeholder"
      class="calendar-input"
      autocomplete="off"
      style="cursor:pointer;">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HotelDatepicker from 'hotel-datepicker'
import { orderModel }  from 'store/house_show/model';

export default {
  data: function() {
    return {
      dirty: false,
      hdpkr: null,
      checkinDate: null,
      checkoutDate: null,
      maxBookingWindowDays: this.$store.state.maxBookingWindowDays,
      separator: ' -> ',
      placeholder: I18n.t('houses.dates_picker.check-in') + ' -> ' + I18n.t('houses.dates_picker.check-out'),
      ycoords: null
    }
  },

  computed: {
    calendarOptions: function() {
      return {
        moveBothMonths: true,
        autoClose: true,
        startDate: new Date(),
        endDate: moment().add(this.maxBookingWindowDays, 'days').toDate(),
        disabledDates: this.disabledDates,
        enableCheckout: true,
        separator: this.separator,
        onSelectRange: this.updateDateRange,
        i18n: window.I18n.t('houses.hotel_datepicker'),
        showTopbar: false,
        animationSpeed: ".2s"
      }
    },

    checkInText: function() {
      if (!!this.$store.state.order.starts_at && this.$store.state.order.starts_at !== '') {
        return moment(this.$store.state.order.starts_at).format('YYYY-MM-DD')
      } else {
        return ''
      }
    },

    checkOutText: function() {
      if (!!this.$store.state.order.ends_at && this.$store.state.order.ends_at !== '') {
        return moment(this.$store.state.order.ends_at).format('YYYY-MM-DD')
      } else {
        return ''
      }
    },

    isAllowedToSetDefaultValue: function() {
      if (!this.checkInText || !this.checkOutText) return false

      return !(this.disabledDates && this.disabledDates.some(date => {
        return moment(date).isBetween(this.checkInText, this.checkOutText, undefined, '[)')
      }))
    },

    ...mapGetters([
      'disabledDates',
      'isDatesSelected'
    ])
  },


  methods: {
    mouseenter: function(){
      if (this.hdpkr && this.ycoords != event.clientY) {
        this.hdpkr.destroy();
        this.setDatepicker();
      }
      this.ycoords = event.clientY
      this.hdpkr.open();
    },

    mouseleave: function(){
      this.hdpkr.close();
    },

    setDatepPickerDefaultValue: function() {
      if (this.isAllowedToSetDefaultValue) {
        this.hdpkr.setValue(this.checkInText + this.separator + this.checkOutText)
      }
    },

    updateDateRange: function() {
      this.checkinDate = this.hdpkr.getValue().split(this.separator)[0]
      this.checkoutDate = this.hdpkr.getValue().split(this.separator)[1]
      $.magnificPopup.close();
      this.setURL();
    },

    setURL() {
      let queryString = `start_date=${this.checkinDate}&end_date=${this.checkoutDate}`
      const roomId = (/room=(\d+)/.exec(location.search) || [])[1]
      if (roomId) queryString = `${queryString}&room=${roomId}`
      let URL = `${this.$store.state.housePath}?${queryString}`
      if (!this.isDatesSelected) {
        Turbolinks.visit(URL)
      } else {
        // fade in the loading animation
        $('[data-loader="circle-side"]').fadeIn()
        $('#preloader').fadeIn()
        // ajax to fetch availability
        let ajaxURL = `${this.$store.state.housePath}/rooms_availability?${queryString}`
        var self = this;
        let numberOfDays = moment(this.checkoutDate, 'YYYY-MM-DD').diff(moment(this.checkinDate, 'YYYY-MM-DD'), 'days');
        Rails.ajax({
          beforeSend: () => true,
          url: ajaxURL,
          type: "GET",
          dataType: 'json',
          contentType: 'application/json',
          success: function(response) {
            self.$updatePageData(self, response, numberOfDays)
            // initialize dates
            self.$store.dispatch("initializeDates", {starts_at: self.checkinDate, ends_at: self.checkoutDate})
            // replace url
            window.history.replaceState({turbolinks: true, url: URL}, '', URL);
            // fade out the loading animation
            $('[data-loader="circle-side"]').fadeOut()
            $('#preloader').fadeOut('slow')
          },
          error: function(error) {
            notification(I18n.t("error") + ': ' + I18n.t("houses.show.error_500") + error);
          }
        })
      }
    },

    setDatepicker() {
      this.hdpkr = new HotelDatepicker(document.getElementById('hdpkr'), this.calendarOptions)
      this.setDatepPickerDefaultValue()
    },

  },

  watch: {
    checkInText() {
      this.setDatepPickerDefaultValue()
    },
    checkOutText() {
      this.setDatepPickerDefaultValue()
    }
  },

  mounted() {
    this.setDatepicker()
  },
}
</script>

<style lang="scss">
@import 'hotel-datepicker/dist/css/hotel-datepicker.css';
#datepicker-hdpkr {
  line-height: 19px;
  width: 300px !important;
  @media (min-width: 646px) and (max-width: 991px) {
    width: 560px !important;
  }
  .datepicker__month {
    font-size: 14px;
  }
  .datepicker__months {
    &:before {
      background: transparent;
    }
    @media (max-width: 645px) {
      #month-2-hdpkr {
        display:none;
      }
    }
    @media (min-width: 646px) and (max-width: 991px) {
      &:before {
        background: #dcdcdc;
      }
    }
  }
  .datepicker__month-day--first-day-selected, .datepicker__month-day--last-day-selected {
    background-color: var(--primary) !important;
    color: #fff;
  }
  .datepicker__month-day--selected, .datepicker__month-day--hovering {
    background-color: rgba(var(--primary-text), 0.5);
    color: #fff;
  }

  .datepicker__month-day.datepicker__month-day--visibleMonth.datepicker__month-day--valid {
    color: rgb(72, 76, 85);
  }
}
#month-1-hdpkr, #month-2-hdpkr{
  width: 100%;
  @media (min-width: 646px) and (max-width: 991px) {
    width: 50% !important;
  }
}
.calendar-input {
  width: 100%;
  padding-left:20%;
  min-height: 50px;
  @media (max-width: 991px) {
    padding-left:0;
    text-align: center;
  }
}
</style>
