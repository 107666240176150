<template>
  <!-- Register Popup -->
  <div id="register-dialog" class="zoom-anim-dialog mfp-hide register_dialog">
    <aside>
      <div class="small-dialog-header small_dialog_header">
        <h3>{{ $i18n("signup") }}</h3>
      </div>
      <form autocomplete="off">
        <div class="form-group">
          <label>{{ $i18n("your_name") }}</label>
          <input class="form-control" type="text" v-model="first_name">
          <i class="ti-user"></i>
        </div>
        <div class="form-group">
          <label>{{ $i18n("your_last_name") }}</label>
          <input class="form-control" type="text" v-model="last_name">
          <i class="ti-user"></i>
        </div>
        <div class="form-group">
          <label>{{ $i18n("your_email") }}</label>
          <input class="form-control" type="email" v-model="email">
          <i class="icon_mail_alt register_icon"></i>
        </div>
        <div class="form-group">
          <label>{{ $i18n("your_password") }}</label>
          <input class="form-control" type="password" id="password1" v-model="password">
          <i class="icon_lock_alt register_icon"></i>
        </div>
        <div class="form-group">
          <label>{{ $i18n("confirm_password") }}</label>
          <input class="form-control" type="password" id="password2" v-model="password_confirmation">
          <i class="icon_lock_alt register_icon"></i>
        </div>
        <div id="pass-info" class="clearfix"></div>
        <p class="btn_1 rounded full-width ajax-button" :class="[isEnabled ? '' : 'disabled', { loading } ]" @click="register">{{ $i18n("register") }}</p>
        <div class="text-center add_top_10">{{ $i18n("already_have_an_account") }} <span class="register" @click="signIn">{{ $i18n("login") }}</span></div>
      </form>
    </aside>
  </div>
  <!-- /Register Popup -->
</template>

<script>
export default {
  data: function() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      loading: false
    }
  },
  computed: {
    isEnabled() {
      return this.validateForm();
    }
  },
  watch: {
    email() {
      var emailInfo = $('#pass-info');
      if (this.validateEmail()) {
        emailInfo.removeClass().html('');
      } else {
        emailInfo.removeClass().addClass('weakpass').html(I18n.t("invalid_email"));
      }
    },
    password() {
      var passwordsInfo = $('#pass-info');
      //Must contain 6 characters or more
      var WeakPass = /(?=.{6,}).*/;
      //Must contain lower case letters and at least one digit.
      var MediumPass = /^(?=\S*?[a-z])(?=\S*?[0-9])\S{6,}$/;
      //Must contain at least one upper case letter, one lower case letter and one digit.
      var StrongPass = /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])\S{6,}$/;
      //Must contain at least one upper case letter, one lower case letter and one digit.
      var VryStrongPass = /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{6,}$/;

      if (VryStrongPass.test(this.password)) {
        passwordsInfo.removeClass().addClass('vrystrongpass').html(I18n.t("vrystrongpass"));
      }	else if (StrongPass.test(this.password)) {
        passwordsInfo.removeClass().addClass('strongpass').html(I18n.t("strongpass"));
      }	else if (MediumPass.test(this.password)) {
        passwordsInfo.removeClass().addClass('goodpass').html(I18n.t("goodpass"));
      } else if (WeakPass.test(this.password)) {
        passwordsInfo.removeClass().addClass('stillweakpass').html(I18n.t("stillweakpass"));
      } else {
        passwordsInfo.removeClass().addClass('weakpass').html(I18n.t("weakpass"));
      }
    },
    password_confirmation() {
      var passwordsInfo = $('#pass-info');
      if (this.password !== this.password_confirmation) {
        passwordsInfo.removeClass().addClass('weakpass').html(I18n.t("password_dont_match"));
      } else if (/(?=.{6,}).*/.test(this.password) == false) {
        passwordsInfo.removeClass().addClass('weakpass').html(I18n.t("at_least_6_chars"));
      } else {
        passwordsInfo.removeClass().addClass('goodpass').html(I18n.t("password_match"));
      }
    }
  },
  methods: {
    validateEmail() {
      //Email Regex
      var Email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return Email.test(this.email) ? true : false
    },
    validateForm() {
      return !!this.first_name &&
        !!this.last_name &&
        !!this.email &&
        !!this.password &&
        !!this.password_confirmation &&
        this.validateEmail() &&
        this.password == this.password_confirmation &&
        /(?=.{6,}).*/.test(this.password) == true
    },
    register() {
      if (this.validateForm() == false) {return;}
      var self = this;
      self.loading = true;
      Rails.ajax({
        beforeSend: () => true,
        url: '/users',
        type: "POST",
        data: this.$json_to_params({'booking_engine_user[preferred_first_name]': this.first_name,
                                    'booking_engine_user[preferred_last_name]': this.last_name,
                                    'booking_engine_user[email]': this.email,
                                    'booking_engine_user[password]': this.password,
                                    'booking_engine_user[password_confirmation]': this.password_confirmation}),
        dataType: 'json',
        contentType: 'application/json',
        success: function(response) {
          sessionStorage.setItem('reloadPageAfterAjaxLogIn', 'true');
          location.reload();
        },
        error: function(response) {
          notification(response);
        },
        complete() {
          self.loading = false;
        }
      })
    },
    signIn() {
      $.magnificPopup.open({
        items: {
          src: '#sign-in-dialog',
        }
      });
    }
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.register_icon {
  top: 30px !important;
}
.small_dialog_header {
  margin-bottom: 20px !important;
}
.register_dialog {
  padding-bottom: 20px !important;
}
.register {
  color: var(--primary);
  font-weight: 500;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.disabled {
  background-color: darkgray !important;
  cursor: default !important;
  &:hover {
    color: #fff;
  }
}
</style>
