
<template>
  <span>
    <div class="form_title">
      <h3><strong>3</strong>{{ $i18n('airhost.checkout.payment.coupon_store_credit') }}</h3>
    </div>

    <div class="step row">
      <div class="form-group form-group-default col-lg-8">
        <label class="fade">{{ $i18n('airhost.checkout.payment.coupon_code') }}</label>
        <input type="text"
               class='form-control'
               v-model="couponCode"
               :disabled="hasCoupon"
               :class="hasCoupon ? 'disabled' : ''"
        />
      </div>

      <p class="col-lg-3 btn_1 coupon_button"
         @click="removeCouponCode"
         v-if="hasCoupon">
         {{ $i18n('airhost.checkout.payment.remove_coupon') }}
      </p>
      <p class="col-lg-3 btn_1 coupon_button light"
         @click="applyCouponCode"
         :class="(ditryInput || !applying) ? '' : 'disabled'"
         v-else>
         {{ $i18n('airhost.checkout.payment.apply_coupon') }}
      </p>
    </div>

    <div class="step row apply-status">
      <span class="col-lg-8 smaller-font" v-if="hasCoupon">
        <span class="status green">
          {{ $i18n('success') }}
        </span>
        {{ $i18n('airhost.checkout.payment.apply_promotion_success') }}
      </span>

      <span class="col-lg-8 smaller-font" v-else-if="hasError">
        <span class="status">
          {{ $i18n('fail') }}
        </span>
        {{ errorMessage }}
      </span>
    </div>
  </span>
</template>

<script>

export default {
  components: {
  },
  props: {
    existing_coupon_code: String,
  },
  data: function() {
    return {
      applying: false,
      dirtyInput: false,
      hasError: false,
      errorMessage: '',
      couponCode: this.$store.state.order.coupon_code || ''
    }
  },
  computed: {
    ditryInput() {
      return this.dirtyInput;
    },
    hasCoupon() {
      return this.$store.state.order.adjustments.length > 0 ? true : false
    },
    orderNumber() {
      return this.$store.state.order.number
    }
  },
  watch: {
    couponCode(value) {
      this.dirtyInput = value.length > 0;
    }
  },
  methods: {
    applyCouponCode() {
      if (this.applying) { return }
      if (!this.dirtyInput) { return }
      if (!this.couponCode) {
        notification(I18n.t("error") + ': ' + I18n.t("airhost.checkout.payment.empty_coupon"));
        return;
      }
      this.applying = true;
      this.$store.commit('setCanCheckout', false);
      var self = this;
      Rails.ajax({
        beforeSend: () => true,
        url: '/promotion',
        type: "POST",
        data: this.$json_to_params({'code': this.couponCode, 'order_number': this.orderNumber, 'type': 'apply'}),
        dataType: 'json',
        contentType: 'application/json',
        success: function(response) {
          if (response.errors) {
            self.hasError = true;
            self.errorMessage = response.errors;
            notification(I18n.t("error") + ': ' + response.errors);
          } else {
            self.$store.dispatch("refreshOrder", self.$json_to_params({'order_number': self.orderNumber}));
            notification(I18n.t("airhost.checkout.payment.apply_promotion_success"), 'success');
          }
          self.applying = false;
          self.$store.commit('setCanCheckout', true);
        },
        error: function() {
          self.applying = false;
          self.$store.commit('setCanCheckout', true);
        }
      })
    },
    removeCouponCode() {
      this.$store.commit('setCanCheckout', false);
      var self = this;
      Rails.ajax({
        beforeSend: () => true,
        url: '/promotion',
        type: "POST",
        data: this.$json_to_params({'order_number': this.orderNumber, 'type': 'remove'}),
        dataType: 'json',
        contentType: 'application/json',
        success: function(response) {
          if (response.errors) {
            notification(I18n.t("error") + ': ' + response.errors);
          } else {
            self.$store.dispatch("refreshOrder", self.$json_to_params({'order_number': self.orderNumber}));
            notification(I18n.t("airhost.checkout.payment.remove_promotion_success"), 'success');
          }
          self.$store.commit('setCanCheckout', true);
        },
        error: function() {
          self.$store.commit('setCanCheckout', true);
        }
      })
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.coupon_button {
  height: 135%;
  margin: 2px 0px 0px 8%;
  font-weight: 400;
  &.light {
    color: #495057;
    background-color: #fff;
    border: 1px solid #d2d8dd;
    &:hover {
    color: black;
    border: 1px solid #495057;
    }
  }
}
.apply-status {
  padding-top: 0;
}
.smaller-font {
  font-size: 95%;
  padding-left: 0;
}
.status {
  background-color: #dc3545;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  margin: 0px 10px 0px 0px;
}
.green {
  background-color: #28a745;
}
.disabled {
  color: #6c757d;
}
</style>
