<template>
  <div>
    <div v-if="!isDatesSelected">
      <div v-for="plan in ratePlans" :key="plan.id" style="padding-top: 20px !important;">
        <div class="row no-gutters no-data-display">
          <div class="col-lg-6 rate-plan-options">
            <h4>{{ ratePlanSummary(plan) }}</h4>
            <read-more :trunc-limit="100"><span class="wrap" v-html="ratePlanDescription(plan)"></span></read-more>
          </div>
          <div class="col-lg-6 room-config">
            <h3 class="price">{{ `${currencySymbol} ...` }} <span>{{ $i18n('houses.show.per_night') }}</span></h3>
            <p v-html="$i18n('houses.show.price_not_available')" class='text-secondary'></p>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters sold-out" v-else-if="soldOut">
      <div class="col-3 col-md-2">
        <img v-bind:src="soldOutImage" style="margin:auto" class="emoticon img-fluid" alt="Sold Out" />
      </div>
      <div class="col-9 col-md-10">
        <h3>{{$i18n('houses.show.sold_out')}}</h3>
        <p v-html="$i18n('houses.show.not_enough_rooms', {start_date: humanizeDate(startsAt), end_date: humanizeDate(endsAt)})" style="margin-bottom: 10px;"></p>
        <div class="row no-gutters">
          <div>
            {{$i18n('houses.show.suggested_dates')}}: &nbsp;&nbsp;
          </div>
          <div>
            <span v-for="dateRange in suggestedDateRanges" :key="dateRange.start_date+dateRange.end_date" class="link" @click="reloadPageWithNewDateParams(dateRange.start_date, dateRange.end_date)">
              {{humanizeDate(dateRange.start_date)}} ~ {{humanizeDate(dateRange.end_date)}}
              <br />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="rate-plan" v-else>
      <rate-plan v-for="plan in ratePlans"
      :masterRoomId="masterRoomId"
      :ratePlan="plan"
      :key="plan.id"
      :rate-plan-availability="ratePlanAvailability(plan.master_id)"
      >
      </rate-plan>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RatePlan from './rate_plan'
import { orderModel }  from 'store/house_show/model';

export default {
  components: { RatePlan },
  props: {
    soldOutImage: String,
    masterRoomId: Number,
    roomId: Number,
    ratePlans: Array,
  },
  data: function() {
    return {
      roomSize: 0,
      lineItems: [],
      currencySymbol: $airhost_settings.currencies[$airhost_settings.currency].symbol
    }
  },

  computed: {
    ...mapGetters([
      'isDatesSelected'
    ]),
    startsAt: function(){
      return this.$store.state.order.starts_at
    },
    endsAt: function(){
      return this.$store.state.order.ends_at
    },
    ratePlansAvailability: function(){
      return this.$store.getters.availabilityByMasterRoomId(this.masterRoomId)
    },
    // check allotments are availability on this date range
    // if room allotment is sold out, we return as sold out,
    // if not, we check rate plans allotments to determine if its sold out.
    soldOut: function(){
      return Object.keys(this.ratePlansAvailability).some(date => {
        const room = this.ratePlansAvailability[date]
        // check room allotment first, then double check at least one rate plans is availability when room is availability
        return room.allotment <= 0 || Object.keys(room).filter(key => key != 'allotment').every(key => room[key].closed == 'true')
      })
    },
    suggestedDateRanges(){
      // 1. get all the dates that have allotments for this room, +-1month (only exists for rooms with no allotments)
      let suggestedDates = this.$store.state.availability.suggested_dates[this.masterRoomId]
      // 2. loop through the dates and find dates that have the next N days also available, N being the numnber of days required
      let numberOfDaysArray = Array.from(Array(this.$store.state.numberOfDays), (e,i)=>i+1)
      let filteredSuggestedDates = suggestedDates
      suggestedDates.forEach( date => {
        numberOfDaysArray.forEach( number => {
          let otherDate = moment(date).add(number, 'days').format("YYYY-MM-DD")
          if (!suggestedDates.includes(otherDate)) {
            filteredSuggestedDates = filteredSuggestedDates.filter(e => e != date)
          }
        })
      })
      // 3. find the closest 3 results to the original selection
      let sortingArray = []
      filteredSuggestedDates.forEach( date => {
        sortingArray.push({date: date, timeDifference: Math.abs(moment(this.$store.state.order.starts_at).diff(moment(date)))})
      })
      sortingArray.sort((a,b) => a.timeDifference - b.timeDifference) // sort by closest first
      let result = sortingArray.slice(0, 3).map( i => {
        return {start_date: i.date, end_date: moment(i.date).add(this.$store.state.numberOfDays, 'days').format("YYYY-MM-DD")}
      })
      return result;
    }
  },
  methods: {
    ratePlanAvailability: function(masterRatePlanId){
      return Object.keys(this.ratePlansAvailability).map((item) => this.ratePlansAvailability[item][masterRatePlanId])
    },
    humanizeDate: function(date_string){
      moment.locale($airhost_settings.locale == 'zh' ? 'zh-CN' : $airhost_settings.locale)
      return moment(date_string).format('ll')
    },
    roomParams: function(){
      let roomId = (/room=(\d+)/.exec(location.search) || [])[1]
      return !!roomId ? `&room=${roomId}` : ''
    },
    reloadPageWithNewDateParams(startDate, endDate){
      let URL = `${this.$store.state.housePath}?start_date=${startDate}&end_date=${endDate}${this.roomParams()}`
      // fade in the loading animation
      $('[data-loader="circle-side"]').fadeIn()
      $('#preloader').fadeIn()
      // ajax to fetch availability
      let ajaxURL = `${this.$store.state.housePath}/rooms_availability?start_date=${startDate}&end_date=${endDate}${this.roomParams()}`
      let numberOfDays = moment(endDate, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days');
      var self = this;
      Rails.ajax({
        beforeSend: () => true,
        url: ajaxURL,
        type: "GET",
        dataType: 'json',
        contentType: 'application/json',
        success: function(response) {
          self.$updatePageData(self, response, numberOfDays)
          // initialize dates
          self.$store.dispatch("initializeDates", {starts_at: startDate, ends_at: endDate})
          // replace url
          window.history.replaceState({turbolinks: true, url: URL}, '', URL);
          // fade out the loading animation
          $('[data-loader="circle-side"]').fadeOut()
          $('#preloader').fadeOut('slow')
        },
        error: function(error) {
          notification(I18n.t("error") + ': ' + I18n.t("houses.show.error_500") + error);
        }
      })
    },
    ratePlanSummary: function(plan){
      return !!plan.summary ? plan.summary[I18n.locale] : ''
    },
    ratePlanDescription: function(plan) {
      return !!plan.description2 ? plan.description2[I18n.locale] : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.no-data-display {
  border: 1px solid #dedede;
  background-color: white;
  padding: 20px;
}
.link{
  text-decoration: underline;
  color: var(--primary);
  &:hover {
    cursor: pointer;
    color: var(--secondary);
  }
}
.wrap {
  overflow-wrap: break-word;
}
</style>
