import initialState from 'store/checkout/model';
import CheckoutOrderSummary from '../components/checkout//order_summary/order_summary';

export default {
  components: { CheckoutOrderSummary },
  data: function() {
    return {}
  },
  computed: {
  },
  methods: {},
  mounted: function() {
    // Modal Sign In
    $('.sign-in').magnificPopup({
      type: 'inline',
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      closeOnBgClick: false,
      enableEscapeKey: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
      mainClass: 'my-mfp-zoom-in'
    });
  },
  beforeMount: function(){
    // clear vuex cache
    this.$store.replaceState(new initialState());
    // map other data to vuex store. order must be the same as show page
    let [...other_keys] = Object.keys($airhost_data);
    for (let key of other_keys) {
      this.$store.state[key] = $airhost_data[key];
    }
  }
}
