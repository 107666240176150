<template>
  <div class="map" id="mapdiv">
  </div>
</template>

<script>
export default {
  props: {
    loadMap: Boolean,
  },
  data: function() {
    return {
      locationsData: [],
      markers: []
    }
  },
  computed: {
  },
  methods: {
    loadGoogleMap() {
      var self = this;
      var locationsData, mapEl, options;
      mapEl = document.getElementById('mapdiv');

      if (this.locationsData.length > 0) {
        options = {
          center: this.locationsData[0].location,
          zoom: 15,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            mapTypeIds: ['hybrid','roadmap','satellite','terrain']
          },
          fullscreenControl: false,
          streetViewControl: false
        };
        var map = new google.maps.Map(mapEl, options);
        var infoWindow = new google.maps.InfoWindow({maxWidth: 250});
        var bounds = new google.maps.LatLngBounds();

        let filteredLocationsData = []
        this.locationsData.forEach(currentData => {
          // check if the filtered array has any same locations, append data instead
          let existingItem = filteredLocationsData.filter(item => item.location.lat == currentData.location.lat && item.location.lng == currentData.location.lng)

          let content = ''
          let description = ''
          let address = ''
          if (existingItem.length > 0) {
            // remove old item from filtered array
            filteredLocationsData = filteredLocationsData.filter(item => item.location.lat != currentData.location.lat && item.location.lng != currentData.location.lng)
            // append/overwrite content
            content = existingItem[0].content
            description = ''
            address = existingItem[0].address
          } else {
            content = ''
            description = `<p>${currentData.description.replace(/target="_blank"/g, '')}</p>`
            address = `${currentData.address}`
          }
          // add content
          content = content + `<h4><a href="${currentData.link}" target="_blank" rel="noopener noreferrer">${currentData.summary} <i class="fa fa-external-link"></i></a></h4>`
          if (self.$store.state.startDate && self.$store.state.endDate) {
            content = content +  `<span style="color: ${currentData.available ? '#32a067' : '#fc5b62'}">${currentData.available ? I18n.t('houses.index.available') : I18n.t('houses.index.sold_out')}</span>` + '<br />' + '<br />';
          }

          currentData.content = content
          currentData.description = description
          currentData.address = address
          filteredLocationsData.push(currentData)
        })

        // construct content for marker's info window
        filteredLocationsData.forEach(function(d) {
          // create marker
          let marker = new google.maps.Marker({
            position: d.location,
            map: map,
            animation: google.maps.Animation.DROP,
            info: d.content + d.description + d.address
          });
          // add listener for marker
          google.maps.event.addListener(marker, 'click', function() {
            if (infoWindow) infoWindow.close();
            infoWindow.setContent( this.info );
            infoWindow.open( map, this );
            // bounce one time animation on click
            marker.setAnimation(google.maps.Animation.BOUNCE)
            setTimeout(function () {
              marker.setAnimation(null);
            }, 700);
          });
          // make all markers fit into one map, only if 2 or more markers
          if (self.locationsData.length > 1) {
            bounds.extend(d.location);
            map.fitBounds(bounds); // google maps codes flaw: every time you add 1 bound, must refit again, doesnt work if code is outside loop.
          }
          // add markers to vue data to remove listeners before destroy
          self.markers.push(marker)
        });
      }
    }
  },
  watch: {
    loadMap() {
      if (this.loadMap == true) {
        this.loadGoogleMap();
      }
    }
  },
  mounted() {
    this.$store.state.houses.forEach(house => {
      // create a data hash for google maps marker's infowindow
      if (house.location != null) {
        let locationData = {}
        locationData.address = house.address
        // convert lon to lng, because ES only allows lon and google only allows lng
        locationData.location = {'lat': house.location.lat, 'lng': house.location.lon}
        locationData.summary = house.summary[I18n.locale]
        locationData.description = this.$truncate_html_str(house.description[I18n.locale] || '', 120)
        if (this.$store.state.startDate && this.$store.state.endDate) {
          locationData.link = `${window.location.origin}${this.$store.state.housePath}/${house.id}?start_date=${moment(this.$store.state.startDate).format('YYYY-MM-DD')}&end_date=${moment(this.$store.state.endDate).format('YYYY-MM-DD')}`
        } else {
          locationData.link = `${window.location.origin}${this.$store.state.housePath}/${house.id}`
        }
        locationData.available = this.$store.state.maxRoomsAvailabileByHouse[house.id] > 0 ? true : false
        this.locationsData.push(locationData)
      }
    })
  },
  beforeDestroy() {
    // Remove all listeners from markers
    this.markers.forEach(marker => {google.maps.event.clearInstanceListeners(marker);})
  },
}
</script>

<style lang="scss" scoped>
</style>
