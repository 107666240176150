import FloatingSelect from '../components/utils/floating_select';
import SignIn from '../components/utils/sign_in';
import Register from '../components/utils/register';
import ResetPassword from '../components/utils/reset_password';

export default {
  components: {FloatingSelect,
              SignIn,
              Register,
              ResetPassword},
  data: function() {return {}},
  computed: {},
  methods: {},
  mounted: function() {},
  beforeMount: function(){
    // notification after reloads
    if (!!sessionStorage.getItem('reloadPageAfterAjaxLogIn')) {
      sessionStorage.removeItem('reloadPageAfterAjaxLogIn');
      notification(I18n.t('signed_in'), 'success')
    }
    if (!!sessionStorage.getItem('reloadPageAfterAjaxResetPassword')) {
      sessionStorage.removeItem('reloadPageAfterAjaxResetPassword');
      notification(I18n.t('password_reset'), 'success', 0)
    }

    // Modal Sign In
    $('.sign-in').magnificPopup({
      type: 'inline',
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      closeOnBgClick: false,
      enableEscapeKey: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
      mainClass: 'my-mfp-zoom-in'
    });

    // // Show Password
    // $('#password').hidePassword('focus', {
    //   toggle: {
    //     className: 'my-toggle'
    //   }
    // });

    // // Forgot Password
    // $("#forgot").click(function () {
    //   $("#forgot_pw").fadeToggle("fast");
    // });

    // hide loading animation
    $('[data-loader="circle-side"]').fadeOut()
    $('#preloader').fadeOut('slow')
  }
}
