<template>
  <!-- Reset Password Popup -->
  <div id="reset-password-dialog" class="zoom-anim-dialog mfp-hide reset_password_dialog">
    <div class="small-dialog-header small_dialog_header">
      <h3>{{ $i18n("reset_password") }}</h3>
    </div>
    <div class="reset-password-wrapper">
      <div v-show="hasError" class="ajax-error">{{ $i18n("invalid_email") }}</div>
      <form autocomplete="off">
        <div class="form-group">
          <label>{{ $i18n("enter_email") }}</label>
          <input type="email" class="form-control" name="email_forgot" v-model="email">
          <i class="icon_mail_alt reset_password_icon"></i>
        </div>
        <p>{{ $i18n("reset_instructions") }}</p>
        <div class="text-center"><p class="btn_1 full-width ajax-button" :class="{ loading }" @click="resetPassword">{{ $i18n('send_email') }}</p></div>
      </form>
    </div>
  </div>
  <!-- /Reset Password Popup -->
</template>

<script>
export default {
  data: function() {
    return {
      email: "",
      error: false,
      loading: false
    }
  },
  computed: {
    hasError() {
      return this.error;
    }
  },
  methods: {
    resetPassword() {
      var self = this;
      self.loading = true;
      Rails.ajax({
        beforeSend: () => true,
        url: '/users/password',
        type: "POST",
        data: this.$json_to_params({email: this.email}),
        dataType: 'json',
        contentType: 'application/json',
        success: function(response) {
          sessionStorage.setItem('reloadPageAfterAjaxResetPassword', 'true');
          location.reload();
        },
        error: function(response) {
          self.error = true;
        },
        complete() {
          self.loading = false;
        }
      })
    }
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.reset_password_icon {
  top: 30px !important;
}
.small_dialog_header {
  margin-bottom: 20px !important;
}
.reset_password_dialog {
  padding-bottom: 20px !important;
}
</style>
