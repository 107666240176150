import initialState from 'store/house_index/model';
import SearchBar from '../components/house_index/search_bar';
import HousesList from '../components/house_index/houses_list';
import ButtonsBar from '../components/house_index/buttons_bar';


export default {
  components: { SearchBar, ButtonsBar, HousesList },
  data: function() {
    return {};
  },
  computed: {
    rooms: function(){
    }
  },
  methods: {},
  mounted: function() {
  },
  beforeMount: function(){
    // clear vuex cache
    this.$store.replaceState(new initialState());
    this.$store.state.maxRoomsAvailabileByHouse = {}
    this.$store.state.maxGuestsAvailabileByHouse = {}

    // load data from $airhost_data to vuex store
    for (let key of Object.keys($airhost_data)) {
      this.$store.state[key] = $airhost_data[key];
    }

    // PAGINATION
    // clone a copy of all houses, sort by non sold-out first
    let availableHouses = [...$airhost_data.houses]
    let sortingArray = []
    availableHouses.forEach( house => {
      // save rooms and accommodates (max and min) to store, to prevent calling them again
      this.$store.state.maxRoomsAvailabileByHouse[house.id] = this.$store.getters.maxRoomsAvailabileByHouse(house.id)
      this.$store.state.maxGuestsAvailabileByHouse[house.id] = this.$store.getters.maxGuestsAvailabileByHouse(house.id)

      // push data to sorting array
      let maxRoomsAvailable = this.$store.state.maxRoomsAvailabileByHouse[house.id]
      let maxGuestsAvailable = this.$store.state.maxGuestsAvailabileByHouse[house.id]
      if (maxRoomsAvailable >= this.$store.state.rooms && maxGuestsAvailable >= this.$store.state.guests) {
        sortingArray.push({data: house, availability: 3})
      } else if (maxRoomsAvailable >= this.$store.state.rooms && maxGuestsAvailable < this.$store.state.guests) {
        sortingArray.push({data: house, availability: 2})
      } else if (maxRoomsAvailable > 0 && maxRoomsAvailable < this.$store.state.rooms) {
        sortingArray.push({data: house, availability: 1})
      } else {
        sortingArray.push({data: house, availability: 0})
      }
    })

    // sort by name first if date not select
    // ref: https://3.basecamp.com/3708673/buckets/3274963/todos/5894863627
    if (!this.$store.state.startDate || !this.$store.state.endDate) {
      sortingArray.sort((a, b) => this.$alphaCompareFunc(a.data.summary[window.I18n.locale], b.data.summary[window.I18n.locale]))
    } else {
      // sort by availability first
      // if availability is same, then sort by house name alphabetically
      sortingArray.sort((a, b) => {
        if (b.availability !== a.availability) {
          return b.availability - a.availability
        } else {
          return this.$alphaCompareFunc(a.data.summary[window.I18n.locale], b.data.summary[window.I18n.locale]);
        }
      })
    }
    this.$store.state.undisplayedHouses = sortingArray.map( i => {return i.data})

    // load first 6 houses to display from undisplayedHouses to displayedHouses
    this.$store.commit("loadMoreHouseList")
  }
};