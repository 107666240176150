<template>
  <div class="sidebar-content">
    <div class="box_detail booking">
      <div id="box-detail-rate-plan" class="box_detail_rate-plan mfp-hide">
        <datepicker>
        </datepicker>
        <div class="box_detail_content" v-for="(items, room_name) in orderSummary" :key="room_name">
          <h4>{{ room_name }}</h4>
          <div class="box_detail_room" v-for="item in items" :key="item.id">
            <div class="box_detail_room_price">
              <h5>{{ item.name }}</h5>
              <div>
                <span class="box_detail_item_price">{{ calculate(itemPriceWithoutExtraGuestFee(item.price, item.adjustments)) | currency({ symbol: getCurrencySymbol }) }}</span>
                <button @click="removeRoom(item.id)" class="trash_icon"><i class="icon_trash"></i></button>
              </div>
            </div>
            <span class="box_detail_item">{{ descriptionForItem(item)}}</span>
            <div class="box_detail_room_price" v-for="adjustment in item.adjustments" :key="adjustment.name">
              <h5>{{ $i18n( 'adjustments.' + adjustment.name) }}</h5>
              <div>
                <span class="box_detail_item_price">{{ calculate(adjustment.amount) | currency({ symbol: getCurrencySymbol }) }}</span>
                <div class="empty-space"> &nbsp; </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn_1 full-width d-block d-lg-none mfp-close-button mobile-fix" @click="closeMfp">{{ $i18n("houses.show.close") }}</div>
      </div>
      <div class="box_detail_total d-lg-none" v-show="!datesNotSelected">
        <span class="box_detail_total_label enlarge-dates">{{ orderDates }}</span>
        <span v-if="selectedRoomCount > 0" class="box_detail_total_label bottom-bar-margin">{{ $i18n("houses.show.rooms_selected") }}: {{ selectedRoomCount }}</span>
        <span v-else class="box_detail_total_label bottom-bar-margin">{{ $i18n("houses.show.add_rooms_to_book") }}</span>
        <a href="javascript:void(0)" class="open-magnific-popup d-lg-none">{{ $i18n("houses.show.see_details") }}</a>
      </div>
      <div class="book-now-container" :class="{ loading }">
        <button v-if="datesNotSelected"
            key="dateNotSelected"
            class="open-magnific-popup btn_1 full-width d-block d-lg-none"
            :class="{ disabled }"
            :disabled="disabled"
            title="Select Dates">
          {{ $i18n("houses.show.select_dates") }}
        </button>
        <button v-else
            key="DatepickersSelected"
            @click="createOrder"
            href="javascript:void(0)"
            :class="{ disabled }"
            :disabled="disabled"
            class="btn_1 full-width"
            title="Book Now">
          {{ orderTotal | currency({ symbol: getCurrencySymbol }) }} >
        </button>
        <!-- Show this if no dates/rooms are selected. this will timeoutBookNowButton the modal. this is hidden on desktop. -->
        <div class="text-center"><small>{{ $i18n("houses.show.no_money_charge") }}</small></div>
      </div>
    </div>
    <a data-action="hotel#gotoRoomSelection" href="#booking" class="room-selection">Back to Room Selection</a>
  </div>
</template>

<script>
import datepicker from './datepicker'
import objectToFormData from 'object-to-formdata'

export default {
  props: {
  },
  data: function() {
    return {
      loading: false,
      disabled: this.$store.state.disabled || false
    }
  },
  components:{
    datepicker,
  },
  computed: {
    selectedRoomCount: function(){
      return this.$store.state.order.line_items.length
    },
    orderSummary: function(){
      return this.$store.state.summary
    },
    orderTotal: function(){
      return (this.$store.state.order.total/ $airhost_settings.rate).toFixed(2);
    },
    orderDates: function(){
      moment.locale($airhost_settings.locale == 'zh' ? 'zh-CN' : $airhost_settings.locale)
      if ($airhost_settings.locale == 'en') {
        return `${moment(this.$store.state.order.starts_at).format('D MMM')} - ${moment(this.$store.state.order.ends_at).format('D MMM')}`
      } else {
        return `${moment(this.$store.state.order.starts_at).format('MMMDo')} - ${moment(this.$store.state.order.ends_at).format('MMMDo')}`
      }
    },
    datesNotSelected() {
      return !this.$store.state.order.starts_at &&
      !this.$store.state.order.ends_at
    }
  },

  methods: {
    itemPriceWithoutExtraGuestFee(price, adjustments) {
      for (let adjustment of adjustments) {
        if (['extra_guest_fee', 'extra_child_fee', 'extra_infant_fee'].includes(adjustment.name)) {
          price -= adjustment.amount
        }
      }
      return price
    },
    getCurrencySymbol() {
      return $airhost_settings.currencies[$airhost_settings.currency].symbol;
    },
    calculate(number) {
      return (number / $airhost_settings.rate).toFixed(2);
    },
    removeRoom(id) {
      let itemToRemove = this.$store.state.order.line_items.filter((item) => item.index === id)
      // Removing items corresponds to the size of rooms released, set the button to enable.
      itemToRemove.forEach((data) => {
        this.$store.commit("updateRoomBtnDisabled", { roomId: data['master_room_id'], value: false });
      })
      this.$store.dispatch("removeItems", itemToRemove);
    },
    closeMfp() {
      $.magnificPopup.close()
    },
    createOrder: function() {
      sessionStorage.setItem('showPageURL', window.location.href);
      sessionStorage.setItem('storeState', JSON.stringify(this.$store.state));
      this.createOrderAjax();
    },
    createOrderAjax() {
      if (this.$store.state.order.line_items < 1) {
        return notification(I18n.t("cart_empty_error")) // do not send ajax if there is no items in the order
      }
      var self = this; // for re-rendering component when mutating data within the same lifecycle hook
      self.loading = true;
      let data = objectToFormData({airhost_order:this.$store.state.order})

      Rails.ajax({
        beforeSend: () => true,
        url: `/${I18n.locale}/checkout/update/cart`,
        type: "PATCH",
        data: data,
        contentType: 'application/json',
        success: function(response) {
          self.timeoutBookNowButton(self);
        },
        error: function(response) {
          notification(I18n.t("error") + ': ' + I18n.t("houses.show.error_500"));
          self.timeoutBookNowButton(self);
        }
      })
    },

    timeoutBookNowButton(self, timeout_in_milliseconds = 2000) {
      self.loading = false;
      self.disabled = true;
      setTimeout( function() {
        self.disabled = false;
      }, timeout_in_milliseconds);
    },

    descriptionForItem: function(item) {
      let description = "";
      if (item.adult > 0) { description += this.$i18n("houses.show.adults", {count: item.adult}) }
      if (item.child > 0) { description += ',' +  this.$i18n("houses.show.children", {count: item.child}) }
      if (item.infant > 0) { description += ',' + this.$i18n("houses.show.infants", {count: item.infant}) }
      return description;
    }
  },

  mounted: function(){
    // determine if session storage cache should be loaded or deleted
    if (!!sessionStorage.getItem('storeState')) {
      let cachedData = JSON.parse(sessionStorage.getItem('storeState'))
      // check if cached house is same as current house, dates are same as params
      if (this.$store.state.housePath == cachedData.housePath &&
      this.$params('start_date') == cachedData.order.starts_at &&
      this.$params('end_date') == cachedData.order.ends_at) {
        this.$store.replaceState(cachedData); // replace store with cache
      } else {
        sessionStorage.removeItem('storeState'); // clear cache
        sessionStorage.removeItem('showPageURL'); // clear cache
      }
    }

    // Image popups
    $('.open-magnific-popup').magnificPopup({
      items:{
        type: 'inline',
        src: "#box-detail-rate-plan"
      },
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      showCloseBtn: false,
      preloader: false,
      midClick: false,
      removalDelay: 300,
      mainClass: 'box_detail_mfp d-block d-lg-none',
      closeOnBgClick: false,
      callbacks: {
        resize: function() {
          if (window.innerWidth >= 991) {
            this.close()
          }
        }
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  background-color: darkgray !important;
  cursor: default !important;
}
.mfp-close-button {
  margin: 15px auto 0px auto;
  width: 100px;
}
.enlarge-dates{
  font-weight: 600
}
.bottom-bar-margin {
  margin-top: 2px;
}
.trash_icon {
  border: none;
  color: var(--red);
  @media (min-width: 992px) {
    background-color: #f8f8f8;
  }
  @media (max-width: 991px) {
    background-color: white;
  }
    &:hover {
    color: black;
  }
}
.empty-space{
  margin-left: 30px;
  min-width: 50px;
}
@media (max-width: 991px) {
  .mobile-fix {
    margin-top: 25px !important;
    width: 105px !important;
  }
  #box-detail-rate-plan {
    border-radius: 5px !important;
  }
}
</style>
