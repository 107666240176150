<template>
  <div>
    <div :class="layout == 'grid' ? 'wrapper-grid' : ''">
      <div :class="layout == 'grid' ? 'row' : ''">
        <div v-for="house in displayedHouses" :key="house.id" :class="layout == 'grid' ? 'col-xl-4 col-lg-6 col-md-6' : ''">
          <div :class="layout == 'grid' ? 'box_grid' : 'box_list'">
            <div :class="layout == 'grid' ? '' : 'row no-gutters'">

              <div :class="layout == 'grid' ? '' : 'col-lg-5'">
                <figure>
                  <a :href="houseLink(house.id)" target="_blank" rel="noopener noreferrer" >
                    <img :src="house.photo || 'hotel.jpg'" class="img-fluid" alt="" :width="800" :height="533" :class="enoughRoomsAndAccommodatesAvailable(house.id) ? '' : 'reduced-opacity'">
                    <div class="read_more"><span> {{ $i18n('houses.index.read_more') }} </span></div>
                    </a>
                </figure>
              </div>

              <div :class="layout == 'grid' ? '' : 'col-lg-7'">
                <div class="wrapper">
                  <a href="#0" class="wish_bt"></a>
                  <!-- <div class="cat_star"><i class="icon_star"></i><i class="icon_star"></i><i class="icon_star"></i><i class="icon_star"></i></div> -->
                  <h3><a :href="houseLink(house.id)" target="_blank" rel="noopener noreferrer" class="hover-secondary"> {{ house.summary[getLocale()] }} </a></h3>
                  <div class="trix-content">
                    <p v-html="$truncate_html_str(house.description[getLocale()] || '', 180)"></p>
                  </div>

                  <div v-if="isHouseOnSale(house.id)">
                    <span v-if="hasDateParams" class="availability">
                      <span v-if="notEnoughRoomsAvailabile(house.id)" class="not-available">
                        {{ $i18n('houses.index.rooms_available', {rooms: minRoomsAvailabile(house.id)}) }}
                      </span>
                      <span v-else-if="notEnoughAccommodatesAvailabile(house.id)" class="not-available">
                        {{ $i18n('houses.index.accommodates_available', {accommodates: minAccommodatesAvailabile(house.id)}) }}
                      </span>
                    </span>
                    <br><span class="price"> {{ $i18n('houses.index.from') }} <strong>{{ house.lowest_price }}</strong> {{  $i18n(`houses.index.${house['is_apartment_house_style'] ? 'per_room_apartment' : 'per_room'}`) }} </span>
                  </div>
                  <div class="no-rooms-left" v-else>
                    <h6>{{$i18n('houses.index.sold_out')}}</h6>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="text-center" v-if="showLoadMore">
      <a id="load_more" class="btn_1 rounded add_top_30 load-more" @click="loadMore"> {{ $i18n('houses.index.load_more') }} </a>
    </p>
  </div>
</template>

<script>
export default {

  data: function() {
    return {
      showLoadMore: this.$store.state.undisplayedHouses != null && this.$store.state.undisplayedHouses.length > 0
    }
  },
  computed: {
    displayedHouses(){
      return this.$store.state.displayedHouses
    },
    layout() {
      return this.$store.state.layout
    },
    hasDateParams() {
      return this.$store.state.startDate && this.$store.state.endDate
    }
  },
  methods: {
    houseDescription(house_description) {
      let description = house_description[this.getLocale()] || ''
      let truncatedLength = 120
      if (description.includes('target="_blank"')) truncatedLength = 180
      description = description.replace(/target="_blank"/g, '')
      description = description.length > truncatedLength ? description.substring(0,truncatedLength) : description
      return description + '...'
    },
    houseLink(house_id) {
      if (this.$store.state.disabled) {
        return '#'
      } else if (this.$store.state.startDate && this.$store.state.endDate && this.$store.state.rooms) {
        return `${window.location.origin}${this.$store.state.housePath}/${house_id}?start_date=${moment(this.$store.state.startDate).format('YYYY-MM-DD')}&end_date=${moment(this.$store.state.endDate).format('YYYY-MM-DD')}`
      } else {
        return `${window.location.origin}${this.$store.state.housePath}/${house_id}`
      }
    },
    getLocale() {
      return window.I18n.locale
    },
    loadMore() {
      this.$store.commit("loadMoreHouseList")
      this.showLoadMore = this.$store.state.undisplayedHouses.length > 0
      return
    },
    returnOneIfNoQuerystrings() {
      if (!this.$store.state.startDate && !this.$store.state.endDate) { return 1 }
    },
    notEnoughRoomsAvailabile(house_id) {
      return this.minRoomsAvailabile(house_id) < this.$store.state.rooms
    },
    minRoomsAvailabile(house_id) {
      return this.returnOneIfNoQuerystrings() || this.$store.state.maxRoomsAvailabileByHouse[house_id]
    },
    notEnoughAccommodatesAvailabile(house_id) {
      return this.minAccommodatesAvailabile(house_id) < this.$store.state.guests
    },
    minAccommodatesAvailabile(house_id) {
      return this.returnOneIfNoQuerystrings() || this.$store.state.maxGuestsAvailabileByHouse[house_id]
    },
    enoughRoomsAndAccommodatesAvailable(house_id) {
      return !this.notEnoughRoomsAvailabile(house_id) && !this.notEnoughAccommodatesAvailabile(house_id)
    },
    isHouseOnSale(houseId) {
      return this.minRoomsAvailabile(houseId) > 0
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.dates-label {
  background-color: var(--white);
  font-weight: 500;
  color: var(--gray);
  line-height: 50px;
  position: relative;
  border-radius: 2px;
  text-align: left;
  padding-left: 15px;
  margin-left: 5px;
}
.pointer-cursor {
  cursor:pointer;
}
.load-more {
  color: var(--white) !important;
}
.availability{
  font-weight: 600;
  font-size: 1.1em;
  font-style: italic;
}
.available{
  color: var(--green);
}
.not-available{
  color: var(--red);
}
.no-rooms-left {
  h6 {
    color: var(--red);
  }
  border: 1px solid var(--off-black);
  padding: 10px 5px 5px 5px;
  text-align: center;
}
.reduced-opacity {
  opacity: 0.4
}
</style>
