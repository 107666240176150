<template>
  <div class="layout_view">
    <a class="grid pointer-cursor" :class="gridClass" @click="changeLayout('grid')">{{$i18n('houses.index.grid')}} <i class="icon-th"></i> &nbsp; </a> |  &nbsp; 
    <a class="list pointer-cursor" :class="listClass" @click="changeLayout('list')">{{$i18n('houses.index.list')}} <i class="icon-th-list"></i></a>
  </div>
</template>

<script>
export default {

  data: function() {
    return {
      layout: this.$store.state.layout
    }
  },
  computed: {
    gridClass: function() {
      return this.layout == 'grid' ? 'active' : ''
    },
    listClass: function() {
      return this.layout == 'list' ? 'active' : ''
    }
  },
  methods: {
    changeLayout(layout) {
      if (this.layout != layout) {
        this.layout = layout;
        this.$store.dispatch("updateLayout", layout);
        Rails.ajax({
          beforeSend: () => true,
          url: '/vue_ajax/update_houses_layout.json',
          type: "GET",
          data: JSON.stringify({houses_layout: this.layout}),
          contentType: 'application/json'
        })
      }
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.dates-label {
  background-color: var(--white);
  font-weight: 500;
  color: var(--gray);
  line-height: 50px;
  position: relative;
  border-radius: 2px;
  text-align: left;
  padding-left: 15px;
  margin-left: 5px;
}
.pointer-cursor {
  cursor:pointer;
  color: var(--off-black);
  &:hover {
    color: var(--primary) !important;
    transition: all 0.25s ease-in-out;
  }
}
</style>
