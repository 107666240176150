<template>
  <div class="row fixed-footer smooth-border" v-bind:class="[isHouseShowOrPayment ? 'mobile-changes' : '', openWindow ? 'openedWindow' : 'closedWindow']">
    <div v-if="!openWindow">
      <div @click="toggle" class="inner" v-bind:class="isOrders ? 'mobile-changes-2' : ''">
        <i class="icon-globe-1"></i> 
        <span class="hide-if-mobile format_text_size">{{ $i18n("footer.language_and_currency") }}</span>
      </div>
    </div>
    <div v-else>
      <span id="footer-selector" class="language-and-currency-open format_text_size">
        <span class="format_text_color">{{ $i18n("footer.language") }}</span><br>
        <div class="styled-select smooth-border format_height" id="lang-selector" style="width: 50%;">
          <select v-model="selectedLanguage" @change="selectLanguage()">
            <option class="select-option" v-for="language in languages" :value="language" :key="language">{{ $i18n("languages."+language) }}</option>
          </select>
        </div>
        <span class="format_text_color">{{ $i18n("footer.currency") }}</span><br>
        <div class="styled-select smooth-border format_height" id="currency-selector">
          <select v-model="selectedCurrency" @change="selectCurrency()">
            <option v-for="currency in currencies"
                    :key="currency.iso"
                    class="select-option" 
                    :value="currency.iso">
              {{currency.name}} - {{currency.symbol}}
            </option>
          </select>
        </div>
      </span>
      <div class="close-button smooth-border format_text_size" @click="toggle">{{ $i18n("footer.close") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      openWindow: false,
      selectedLanguage: I18n.locale,
      selectedCurrency: $airhost_settings.currency,
      languages: $airhost_settings.languages,
      currencies: $airhost_settings.currencies
    }
  },
  computed: {
    isHouseShowOrPayment() {
      if(isCurrentPath('houses#show') ||
      isCurrentPath('airhost/checkout#address') ||
      isCurrentPath('airhost/checkout#payment')){
        return true;
      }
      return false;
    },
    isOrders() {
      if(isCurrentPath('airhost/orders#index') ||
      isCurrentPath('airhost/orders#show')){
        return true;
      }
      return false;
    }
  },
  methods: {
    toggle() {
      this.openWindow = !this.openWindow;
    },
    selectLanguage() {
      var self = this;
      Rails.ajax({
        beforeSend: () => true,
        url: '/vue_ajax/update_locale.json',
        type: "GET",
        data: JSON.stringify({locale: this.selectedLanguage}),
        contentType: 'application/json',
        success: function(response) {
          let array = window.location.pathname.split("/");
          if (self.languages.includes(array[1]) && self.selectedLanguage != array[1]) {
            array[1] = self.selectedLanguage;
          }
          window.location.replace(window.location.origin + array.join("/") + window.location.search);
        },
        error: function(response) {
          notification(I18n.t("error"));
        }
      })
    },
    selectCurrency() {
      Rails.ajax({
        beforeSend: () => true,
        url: '/vue_ajax/update_currency.json',
        type: "GET",
        data: JSON.stringify({currency: this.selectedCurrency}),
        contentType: 'application/json',
        success: function(response) {
          location.reload();
        },
        error: function(response) {
          notification(I18n.t("error"));
        }
      })
    }
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.fixed-footer {
  position: fixed;
  background-color: #fff;
  border: 1px solid #d2d8dd;
  white-space: nowrap;
  right: 25px;
  bottom: 15px;
  z-index: 10000;
  cursor: pointer;
}
.closedWindow {
  transition: all 0.2s ease-in-out;
  color: #555;
  &:hover {
    color: #fff;
    background-color: var(--primary);
    border: 1px solid var(--primary);
  }
  .inner {
    @media (min-width: 992px) {
      padding: 8px 15px;
    }
  }
  @media (max-width: 991px) {
    color: #fff;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 50% !important;
    height: 40px;
    width: 40px;
    font-size: 160%;
    padding-left: 3px;
    padding-top: 1px;
  }
}
.openedWindow {
  padding: 8px 15px;
}
.mobile-changes {
  @media (max-width: 991px) {
    bottom: 77px;
  }
}
.mobile-changes-2 {
  @media (max-width: 991px) {
    [class^="icon-"]:before, [class*=" icon-"]:before  {
      margin-left: 0.25em;
      margin-top: 7px;
    }
  }
}
.hide-if-mobile {
  @media (max-width: 991px) {
    display:none;
  }
}
.language-and-currency-open {
  width: 200px;
}
.styled-select {
  padding-left: 0;
}
.select-option {
  padding-left: 0 !important;
}
.close-button {
  transition: all 0.2s ease-in-out;
  text-align: center;
  color: var(--primary);
  background-color: #fff;
  border: 1px solid #d2d8dd;
  padding: 8px;
  width: 50%;
  margin: auto;
  white-space: nowrap;
  &:hover {
    color: #fff;
    background-color: var(--primary);
  }
}
.smooth-border {
  border-radius: 5px;
}
select {
  color: #6f787f !important;
  height: 50px !important;
  padding-left: 15px !important;
  width: 110% !important;
  font-weight: 500 !important;
  display: inline-block !important;
  font-size: 0.875rem !important;
  line-height: 1.6 !important;
  font-family: "Poppins", Helvetica, sans-serif !important;
}
.format_text_size {
  font-size: 0.875rem !important;
}
.format_text_color {
  color: #555 !important;
}
.format_height {
  height: 50px !important;
}
.styled-select {
  border: 1px solid #d2d8dd !important;
  margin-bottom: 10px !important;
}
</style>
