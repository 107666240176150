<template>
<div data-controller="room-container" class="room-container">
  <h5>{{ displayFormattedRoom() + ' ' + (id + 1) }}</h5>
  <div class="row no-gutters">
    <div class="col-lg-12">
      <div class="styled-select">
        <select v-model="preferences.adult" class="select-adult" @change='update'>
          <option v-for="adult in adult_count_available" :value="adult" :selected="preferences.adult" :key="adult">
          {{ $i18n("houses.show.adults", {count: adult}) }}</option>
        </select>
      </div>
    </div>
    <div v-if="show_child_and_infant" class="col-lg-7">
      <div class="styled-select">
        <select v-model="preferences.child" class="select-children" @change='update'>
          <option selected value="0">{{ $i18n("houses.show.child") }}</option>
          <option v-for="child in child_counts_available" :value="child" :selected="preferences.child" :key="child">
          {{ $i18n("houses.show.children", {count: child}) }}</option>
        </select>
      </div>
    </div>
    <div v-if="show_child_and_infant" class="col-lg-5">
      <div class="styled-select">
        <select v-model="preferences.infant" class="select-infant" @change='update' :disabled="infantCountsAvailable === 0">
          <option selected value="0">{{ $i18n("houses.show.infant") }}</option>
          <option v-for="infant in infantCountsAvailable" :value="infant" :selected="preferences.infant" :key="infant">
          {{ $i18n("houses.show.infants", {count: infant}) }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="child-age" v-if="preferences.child > 0">
    <p>{{ $i18n("houses.show.age_text") }}</p>
    <div class="styled-select" v-for="(c, index) in preferences.child_counts" v-bind:key="index">
      <select class="select-child-age" v-model="c.age">
        <option v-for="child in age_options" :value="child" :selected="c.age" :key="child">{{ child + ' ' + $i18n("houses.show.years_old") }}</option>
      </select>
    </div>
  </div>
  <p v-if="show_child_and_infant && infantCountsAvailable === 0">{{ $i18n("houses.show.infants_not_allowed") }}</p>
  <p v-if="!show_child_and_infant">{{ $i18n("houses.show.only_adult") }}</p>
  <div>
    <button @click="removeRoom()" class="btn_1 full-width remove-button">{{ $i18n('houses.show.remove') }}</button>
  </div>
</div>
</template>
<script>
export default {
  props: {
    lineItem: Object,
    id: Number,
    accommodates: Number,
    infantCountsAvailable: Number,
  },
  data: function() {
    return {
      preferences: this.lineItem.preferences,
      child_counts: this.lineItem.preferences.child_counts.length,
      adult_count_available: 0,
      child_counts_available: 0,
      age_options: this.$store.state.childAges,
      show_child_and_infant: !this.$store.state.onlyAdult
    }
  },
  methods: {
    removeRoom() {
      // Removing items corresponds to the size of rooms released, set the button to enable.
      this.$store.commit("updateRoomBtnDisabled", { roomId: this.lineItem.master_room_id, value: false })
      this.$store.dispatch("removeItems", [this.lineItem]);
    },
    update: function() {
      let item = Object.assign({}, this.lineItem);
      if (this.child_counts > this.preferences.child){
        this.preferences.child_counts.splice(this.preferences.child, this.child_counts - this.preferences.child)
      }else{
        if (this.child_counts < this.preferences.child) {
          for(let i=0; i<(this.preferences.child - this.child_counts); i++)
          {
            this.preferences.child_counts.push({
              age: this.age_options[0],
              count: 1
            })
          }
        }
      }
      this.child_counts = this.preferences.child
      item.preferences = this.preferences
      this.$store.dispatch("editItem", item)

      // update count remaining for selection
      this.adult_count_available = this.accommodates - this.preferences.child
      this.child_counts_available = this.accommodates - this.preferences.adult
    },
    displayFormattedRoom: function() {
      return this.$store.state.isApartmentHouseStyle ? I18n.t("houses.show.building") : I18n.t("houses.show.room");
    }
  },
  mounted() {
    this.adult_count_available = this.accommodates;
    this.child_counts_available = this.accommodates - 1;
    // trigger update function if children number is from url
    if (this.preferences.child !== null && this.preferences.child > 0) {
      this.update();
    }
  }
}
</script>

<style lang="scss" scoped>
.remove-button{
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
  background-color: rgb(112, 128, 144);
  border-color: rgb(112, 128, 144);
  &:hover {
    background-color: rgb(105, 105, 105);
    border-color: rgb(105, 105, 105);
  }
  @media (min-width: 768px) {
    width: 50%;
    margin-left: 50%;
  }
}
</style>
