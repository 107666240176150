
<template>
  <span>
    <div class="filters_listing sticky_horizontal">
      <div class="container">
        <ul class="clearfix">
          <li>
            <div class="switch-field">
              <input type="radio" id="all" name="listing_filter" value="all" checked>
              <label for="all">All</label>
              <input type="radio" id="popular" name="listing_filter" value="popular">
              <label for="popular">Popular</label>
              <input type="radio" id="latest" name="listing_filter" value="latest">
              <label for="latest">Latest</label>
            </div>
          </li>
          <li>
            <view-mode-toggle></view-mode-toggle>
          </li>
          <li>
            <a
              class="btn_map"
              data-toggle="collapse"
              href="#collapseMap"
              aria-expanded="false"
              aria-controls="collapseMap"
              data-text-swap="Hide map"
              data-text-original="View on map"
              id="view-on-map"
              @click="toggleMap">
                {{ $i18n('houses.index.view_on_map') }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="collapse" id="collapseMap">
      <google-map
        :load-map.sync="loadMap">
      </google-map>
    </div>
  </span>
</template>

<script>
import ViewModeToggle from './view_mode_toggle'
import GoogleMap from './google_map'

export default {
  components: {
    ViewModeToggle,
    GoogleMap
  },
  data: function() {
    return {
      loadMap: false,
    }
  },
  computed: {
  },
  methods: {
    toggleMap() {
      this.loadMap = true;
    }
  },
  mounted() {
    if (this.$store.state.mapStatus==='open') {
      this.toggleMap()
      $('.collapse').collapse('toggle')
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
