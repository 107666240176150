export default function initialState() {
  return {
    houses: [],
    undisplayedHouses: [],
    displayedHouses: [],
    layout: 'grid',
    startDate: null,
    endDate: null,
    rooms: null
  }
}