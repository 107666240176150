export default {
  housePhotoUrl: state => {
    return state.housePhotoUrl
  },
  houseLocalizedSummary: state => {
    return state.houseLocalizedSummary
  },
  orderGuestNum: state => {
    return state.orderGuestNum
  },
  orderStayDuration: state => {
    return state.orderStayDuration
  },
  orderCurrency: state => {
    return state.order.currency
  },
  orderTotal: state => {
    return state.order.total
  },
  orderAdjustments: state => {
    return state.order.adjustments
  },
  orderCouponCode: state => {
    return state.order.coupon_code
  },
  couponCodePresence: state => {
    return !!state.order.coupon_code
  }
}
