<template>
  <span>
    <div id="box-detail-rate-plan" class="box_detail_rate-plan mfp-hide">
      <div class="booking-info">
        <div class="booking-info-image">
          <img :src="housePhotoUrl" :alt="houseLocalizedSummary" />
          <h4>{{ houseLocalizedSummary }}</h4>
        </div>
        <div class="booking-info-guest-dates" style="border-bottom: none; padding-bottom: 0;">
          <span class="booking-info-guests"><span class="icon_group"></span>{{ orderGuestNum }}</span>
          <span class="booking-info-dates"><span class="icon_calendar"></span>{{ orderStayDuration }}</span>
        </div>
      </div>

      <div v-for="productId in lineItemProductIds" :value="productId" :key="productId">
        <div class="box_detail_content">
          <h4>{{ lineItemsByProduct(productId)[0].description }}</h4>
          <line-item
            v-for="(lineItem, index) in lineItemsByProduct(productId)"
            :lineItem="lineItem"
            :index="index"
            :currencyConvert="currencyConvert"
            :orderCurrency="orderCurrency"
            :key="lineItem.id">
          </line-item>
        </div>
      </div>

      <div class="box_detail_others">
        <div class="box_detail_room_price" v-for="adjustment in orderAdjustments" :key="adjustment.name">
          <h5 v-if="adjustment.is_coupon" style="width:50%;">{{ adjustment.name }}</h5>
          <h5 v-else>{{ $i18n('adjustments.' + adjustment.name) }}</h5>
          <span class="box_detail_item_price"> {{ $calculatePrice(adjustment.amount) | currency({ symbol: $currencySymbol() }) }}</span>
        </div>
        <span v-if="couponCodePresence" class="summary-small-font" style="text-align: center;">({{ orderCouponCode }})</span>
      </div>
      <span v-if="currencyConvert">
        <br>
        <span class="summary-small-font">* {{ $i18n('airhost.checkout.disclaimer', {your_currency: userCurrency, house_currency: orderCurrency }) }}</span>
        <br><br>
        <span class="summary-small-font">{{ $i18n('airhost.checkout.disclaimer2') }}</span>
      </span>

      <!-- hidden partial for mobile view only -->
      <div class="align-right add-divider" :class="mobileView ? '' : 'hidden'">
        <span class="box_detail_total_label">{{ $i18n('airhost.checkout.address.total_amount') }}</span>
        <div class="box_detail_total_price" >{{ $calculatePrice(orderTotal) | currency({ symbol: $currencySymbol() }) }}<span v-if="currencyConvert">*</span></div>
        <div v-if="currencyConvert" class="box_detail_total_price color-red">
          <h5><span class="summary-small-font">{{ $i18n('airhost.checkout.price_in_house_currency', {house_currency: orderCurrency}) }}</span></h5>
          {{ orderTotal | currency({ symbol: $currencySymbol(orderCurrency) }) }}
        </div>
      </div>
      <div class="btn_1 full-width d-block d-lg-none mfp-close-button mobile-fix" @click="closeMfp">{{ $i18n("houses.show.close") }}</div>
    </div>

    <!-- displayed for both mobile and web (mobile is displayed as lower left corner)-->
    <a href="#box-detail-rate-plan" class="box_detail_total" :class="mobileView ? '' : 'add-divider'">
      <span class="box_detail_total_label">{{ $i18n('airhost.checkout.address.total_amount') }}</span>
      <div class="box_detail_total_price" :class="mobileView ? 'hidden' : ''">{{ $calculatePrice(orderTotal) | currency({ symbol: $currencySymbol() }) }}<span v-if="currencyConvert">*</span></div>
      <div class="box_detail_total_price" :class="[mobileView ? '' : 'color-red', !mobileView && !currencyConvert ? 'hidden' : '']">
        <h5 v-if="currencyConvert" :class="mobileView ? 'hidden' : ''" style="margin-bottom:0px"><span class="summary-small-font">{{ $i18n('airhost.checkout.price_in_house_currency', {house_currency: orderCurrency}) }}</span></h5>
        {{ orderTotal | currency({ symbol: $currencySymbol(orderCurrency) }) }}
      </div>
      <span class="box_detail_total_label enlarge-dates">{{ orderStayDuration }}</span>
      <span class="d-lg-none">{{ $i18n('houses.show.see_details') }}</span>
    </a>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import LineItem from './line_item.vue'
export default {
  components: {
    LineItem,
  },
  props: {
  },
  data() {
    return {
      userCurrency: ''
    }
  },
  computed: {
    ...mapGetters([
      'refreshOrder', 'housePhotoUrl', 'houseLocalizedSummary',
      'orderGuestNum', 'orderStayDuration', 'orderCurrency', 'orderTotal',
      'orderAdjustments', 'orderCouponCode', 'couponCodePresence'
    ]),
    currencyConvert() {
      return this.$store.state.userExRate == '1' ? false : true
    },
    lineItemProductIds(){
      return Array.from(new Set(this.$store.state.order.line_items.map((item) => item.product_id)))
    },
    mobileView() {
      return window.innerWidth <= 991
    }
  },
  methods: {
    lineItemsByProduct(id) {
      return this.$store.state.order.line_items.filter((item) => item.product_id == id)
    },
    closeMfp() {
      $.magnificPopup.close()
    }
  },
  watch: {
  },
  mounted(){
    this.userCurrency = $airhost_settings.currency

    $('a[href="#box-detail-rate-plan"], .search-toggle.d-lg-none').magnificPopup({
    type: 'inline',
    fixedContentPos: true,
    fixedBgPos: true,
    overflowY: 'auto',
    closeBtnInside: true,
    preloader: false,
    midClick: true,
    removalDelay: 300,
    mainClass: 'box_detail_mfp d-block d-lg-none',
    callbacks: {
      resize: function() {
        if (window.innerWidth >= 991) {
          this.close()
        }
      }
    }
  });
  }
}
</script>
<style lang="scss" scoped>
  .add-divider {
    margin-top: 25px;
    padding-top: 20px;
    border-top: 1px solid #dedede;
  }
  .hidden {
    display: none;
  }
  .color-red {
    color: red;
  }
  .align-right {
    text-align: right;
  }
  .mfp-close-button {
    margin: 15px auto 0px auto;
    width: 100px;
  }
  @media (max-width: 991px) {
    .mobile-fix {
      margin-top: 25px !important;
      width: 105px !important;
    }
  }
  .enlarge-dates{
    font-weight: 600;
  }
</style>
