<template>
  <div :class="roomClass" class="fix-height">
    <div class="col-md-8" v-if="propertyTypeIsApartment"></div>
    <div class="row" v-else>
      <div class="col-md-4">
        <img :src="firstPhotoURL()" class="img-fluid" :title="roomSummary" alt="">
        <div class="gallery-container magnific-gallery" >
          <a :href="firstPhotoURL()" class="img-fluid" :title="roomSummary" alt="" data-effect="mfp-zoom-in">
            <p class="text-center pt-2 mb-0 cursor-pointer">{{$i18n("houses.show.view_gallery")}}</p>
            </a>
          <a :href="room_photo" class="btn_photos" :title="roomSummary" data-effect="mfp-zoom-in" v-for="room_photo of otherPhotoURLs()" :key="room_photo">
          </a>
        </div>
      </div>
      <div class="col-md-8">
        <h4>{{roomSummary}}</h4>
        <read-more :trunc-limit="100" :gray="index % 2 == 0 ? true : false">
          <div v-html="roomDescription"></div>
          <br>
          <!-- <span>{{$i18n("houses.show.room_amenities")}}:</span> -->
          <ul class="bullets">
            <div class="row">
              <div class = "col-lg-6">
                <p v-for="amenity in this.leftAmenityArray" :key="amenity" class="amenity_icon_list">
                  <span v-html="formattedAmenity(amenity)"></span>
                </p>
              </div>
              <div class = "col-lg-6">
                <p v-for="amenity in this.rightAmenityArray" :key="amenity" class="amenity_icon_list">
                  <span v-html="formattedAmenity(amenity)"></span>
                </p>
              </div>
            </div>
          </ul>
        </read-more>
        <br>
      </div>
    </div>
    <rate-plans
      :rate-plans="ratePlans"
      :master-room-id="room.master_id"
      :room-id="room.id"
      sold-out-image="https://triosky-airhost.s3-ap-northeast-1.amazonaws.com/assets/shared/emoticon.svg">
    </rate-plans>
    <!-- /row -->
  </div>
</template>

<script>
import RatePlans from './rate_plans'

export default {
  props: ['room', 'index', 'property_type'],
  components: {RatePlans},
  computed: {
    roomClass: function() { return this.index % 2 == 0 ? 'room_type gray' : 'room_type' },
    roomSummary: function() {
      let text = this.room.summary[window.I18n.currentLocale()]
      return this.simpleFormat(text)
    },
    roomDescription: function() {
      let text = this.room.description[window.I18n.currentLocale()]
      return this.simpleFormat(text)
    },
    ratePlans: function() {
      return this.$store.getters.findRatePlansByRoomId(this.room.id)
    },
    propertyTypeIsApartment: function() {
      return this.property_type == "apartment"
    }
  },

  data: function () {
    return {
      options: [],
      selectedValue: 2,
      fieldName: '',
      leftAmenityArray: [],
      rightAmenityArray: []
    }
  },

  mounted: function(){
    if (!!this.room.settings.amenities) {
      let amenityList = this.room.settings.amenities;
      let amenityIconsAvailable = Object.keys(this.$store.state.amenities_icons);
      let filteredAmenityIcons = [];
      for (let amenity of amenityList) {
        if (amenityIconsAvailable.includes(amenity)) {
          filteredAmenityIcons.push(amenity);
        }
      }
      for (let [index, amenity] of filteredAmenityIcons.entries()) {
        index % 2 == 0 ? this.leftAmenityArray.push(amenity) : this.rightAmenityArray.push(amenity)
      }
    }
  },

  methods: {
    simpleFormat: function(text) {
      if (!text) return text
      text = text.replace(/target="_blank"/g, '')
      return text.replace(/\r\n/g, '<br>')
    },
    firstPhotoURL() {
      return this.room.house_photos[0];
    },
    otherPhotoURLs() {
      return this.room.house_photos.slice(1);
    },
    formattedAmenity: function(amenity) {
      return `<i class="${this.$store.state.amenities_icons[amenity]}"></i> ${I18n.t("rooms.amenities." + amenity)}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.room-photo-owl {
  position: relative;
  top: 40% !important
}
@media (max-width: 767px) {
  .room-photo-owl {
    top: 45% !important
  }
}
.mfp-figure {
  max-width: 80% !important;
}
.amenity_icon_list {
  line-height: 1.8;
  margin-bottom: 0px;
}
.fix-height{
  min-height: 0px;
}
</style>