export default {
  updateLayout(state, data) {
    state.layout = data;
  },
  loadMoreHouseList(state) {
    if (state.undisplayedHouses != null){
      state.displayedHouses.push(state.undisplayedHouses.splice(0,6))
      state.displayedHouses = state.displayedHouses.flat()
    }
  },
};