<template>
  <div class="box_detail_room">
    <div class="box_detail_item">{{ lineItem.guest_num }}</div>
    <div class="box_detail_room_price">
      <h5>{{ lineItem.variant_localized_name }} <br> {{ $i18n('houses.show.room') }} {{ index+1 }}</h5>
      <span class="box_detail_item_price">
        {{ $calculatePrice(lineItem.price_without_extra_guest_fee) | currency({ symbol: $currencySymbol() }) }}<span v-if="currencyConvert">*</span>
      </span>
    </div>
    <span v-if="currencyConvert">
      <div style="margin: 5px;"></div>
      <div class="box_detail_room_price">
        <h5><span class="summary-small-font" style="color: red;">{{ $i18n('airhost.checkout.price_in_house_currency', {house_currency: orderCurrency}) }}</span></h5>
        <span class="box_detail_item_price" style="color: red;"><i>{{ lineItem.price_without_extra_guest_fee | currency({ symbol: $currencySymbol(orderCurrency) }) }}</i></span>
      </div>
    </span>
    <br>
    <div class="box_detail_others">
      <div class="box_detail_room_price" v-for="adjustment in lineItem.adjustments" :key="adjustment.name">
        <h5>{{ $i18n('adjustments.' + adjustment.name) }}</h5>
        <span class="box_detail_item_price">
          {{ $calculatePrice(adjustment.amount) | currency({ symbol: $currencySymbol() }) }}
        </span>
      </div>
      <span v-if="priceAdjustmentExist">
        <br>
        <div class="box_detail_room_price">
          <h5>{{ $i18n('line_items.length_of_stay_discount') }}</h5>
          <span class="box_detail_item_price">-{{ $calculatePrice(lineItem.price_adjustmented_amount) | currency({ symbol: $currencySymbol() }) }}</span>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    lineItem: Object,
    currencyConvert: Boolean,
    orderCurrency: String,
    index: Number
  },
  data() {
    return {
    }
  },
  computed: {
    priceAdjustmentExist() {
      return this.lineItem.price_adjustmented_amount> 0 ? true : false
    }
  },
  methods: {
  },
  watch: {
  },
  mounted(){
  }
}
</script>

<style lang="scss" scoped>
</style>
