export default {
  data: function() {
    return {
      allowGuestInformation: {}
    }
  },
  methods: {
    toggleGuestInformation: function(id){
      this.allowGuestInformation[id] = !this.allowGuestInformation[id]
      this.$forceUpdate()
    },
    showGuestInformation: function(id){
      return this.allowGuestInformation[id]
    }
  },
  mounted: function() {
    let node = document.querySelector('[data-line-item-ids]')
    if (!!node) {
      node.dataset.lineItemIds.replace('[','').replace(']','').split(", ").forEach( (id) => {
        this.allowGuestInformation[id] = false
      })
    }
  }
}
