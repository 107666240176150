export default {
  removeItems (context, items) {
    items.forEach(
      item => context.commit('removeItem', item)
    )
    context.commit('updateSummary')
  },
  addItemsByRatePlan (context, payload) {
    //Add 'count' default items  for giving ratePlan
    let ratePlan = payload.ratePlan
    let count = payload.count
    let room_data = context.state.rooms.find(
      (r) => r.rate_plans.find(
        (p) => p.id == ratePlan.id))
    if(!room_data) return
    let newItems = []
    for(let i=0; i<count; i++) {
      let ratePlanUID = context.getters.ratePlanUID
      let lineItem = {
        product_type: "Room",
        product_id: ratePlan.room_id,
        product_name: localizedSummary(room_data.summary),
        variant_type: "RatePlan",
        variant_id: ratePlan.id,
        variant_name: localizedSummary(ratePlan.summary),
        preferences: {
          adult: payload.adults || 1,
          child: payload.children || 0,
          infant: 0,
          child_counts: [],
          per_unit_rates: []
        },
        index: ratePlanUID,
        discounted: ratePlan.sync_discount,
        master_room_id: room_data.master_id,
        master_rate_plan_id: ratePlan.master_id,
        adjustments: []
      }
      newItems.push(context.getters.buildLineItem(lineItem))
      context.commit('updateRatePlanUID')
    }
    if(newItems.length > 0){
      context.dispatch('addItems', newItems)
      context.commit('updateSummary')
    }
  },
  addItems (context, items) {
    //TODO: need the code to calculate the total and add per_unit_rates and set the
    items.forEach(
      item => context.commit('addItem', item)
    )
    context.commit('updateSummary')
  },
  editItem(context, item){
    let new_item = context.getters.buildLineItem(item)
    context.commit('editItem', new_item)
    context.commit('updateSummary')
  },
  updateOrder(context, payload){
    context.commit("update" + payload["field"], payload["data"])

    if (payload["field"] === "EndsAt"){
      context.state.order.line_items.forEach(item => {
        let new_item = context.getters.buildLineItem(item)
        context.commit("editItem", new_item)
        return
      })
    }
    if (payload["field"] != "StartsAt"){
      context.commit('updateSummary')
    }
  },
  setOrderCurrency(context, currency){
    context.commit('updateOrderCurrency', currency)
  },
  initializeRoomSort(context) {
    // sort rooms data by name and availability
    let sortedRooms = context.state.rooms.sort(function (r1,r2) {
      let s1 = r1.summary[window.I18n.currentLocale()];
      let s2 = r2.summary[window.I18n.currentLocale()];
      if (!s1) return 1;
      if (!s2) return -1;
      return s1 == s2 ? 0 : s1 > s2 ? 1 : -1;
    });
    let available = []
    let unavailable = []
    sortedRooms.forEach( (room) => {
      let roomAvailable = true
      for (let rp of room.rate_plans) {
        if (!ratePlanAvailable(context, rp)) {
          roomAvailable = false
          unavailable.push(room)
          break
        }
      }
      if (roomAvailable) available.push(room)
    })

    let sortRooms = available.concat(unavailable)

    const givenRoom = findGivenRoom(context)
    if (givenRoom) {
      // move the given room to top one
      sortRooms = [givenRoom].concat(sortRooms.filter(room => room.id !== givenRoom.id))
    }

    context.commit('updateRooms', sortRooms)
  },
  initializeDates(context, data) {
    var promiseChain = Promise.resolve();
    if (!!$airhost_data.startDate && !!$airhost_data.endDate) {
      promiseChain = promiseChain.then(context.dispatch("updateOrder", {field:"StartsAt", data: data.starts_at}));
      promiseChain = promiseChain.then(context.dispatch("updateOrder", {field:"EndsAt", data: data.ends_at}));
    }
    return promiseChain;
  },
  initializeGivenRoom(context) {
    const givenRoom = findGivenRoom(context)
    if (!givenRoom) return

    const ratePlans = givenRoom.rate_plans.filter((rp) => ratePlanAvailable(context, rp))
    if (ratePlans.length === 0) return

    const ratePlan = context.state.ratePlanID === 0 ? ratePlans[0] : ratePlans.find(r => r.id === context.state.ratePlanID)
    if (!ratePlan) return

    let adults = context.state.adults
    let children = context.state.children
    // adults: [1, accommodates]  children: [0, accommodates-adults]
    if (adults < 1 || children < 0 || (children + adults > givenRoom.accommodates)) {
      adults = 1
      children = 0
    }
    context.dispatch('addItemsByRatePlan', {ratePlan: ratePlan, adults: adults, children: children, count: 1})
  }
}

function findGivenRoom(context) {
  if (context.state.roomId === 0) return null
  return context.state.rooms.find(r => r.id === context.state.roomId)
}

// return whether the rate plan is available
function ratePlanAvailable(context, rate_plan) {
  let ratePlanAvailability = context.getters.availabilityByMasterRoomId(rate_plan.master_room_id)
  let mappedAvailability = Object.keys(ratePlanAvailability).map((item) => ratePlanAvailability[item][rate_plan.master_id])
  let min_stay = Math.max(...mappedAvailability.map((data) => data.min_stay ? data.min_stay : 0))
  let max_stay = Math.min(...mappedAvailability.map((data) => data.max_stay).filter(Number))
  let length_of_stay = context.state.numberOfDays
  return min_stay <= length_of_stay && max_stay >= length_of_stay
}

function localizedSummary(summary) {
  let availableLocales = [window.I18n.currentLocale(), 'en', 'ja', 'zh', 'zh-CN', 'ko']
  let locale = availableLocales.find(locale => !!summary[locale])
  return summary[locale]
}
