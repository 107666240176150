
<template>
  <div v-if="propertyTypeIsApartment">
    <ul class="bullets" v-show="room_amenities.length > 0">
      <h4 style="margin-bottom: 20px;">{{ $i18n('houses.show.amenities') }}</h4>
      <div class="row">
        <div v-for="amenity in topSixAmenities" :key="amenity" class="col-lg-6">
          <span v-html="formattedAmenity(amenity)"></span>
          <br>
        </div>
      </div>
      <br>

      <a href="#facilities-dialog" title="Facilities Details" class="facilities-details" @click="openDialog">{{ $i18n('houses.show.view_all_amenities') }}</a>

      <div id="facilities-dialog" class="zoom-anim-dialog mfp-hide">
        <h3 class="facilities-title">{{ $i18n('houses.show.amenities') }}</h3>
        <div class="row">
          <div v-for="amenity in this.filteredAmenities" :key="amenity" class="col-lg-12">
            <p v-html="formattedAmenity(amenity)" style="margin: 5px 0px;"></p>
          </div>
        </div>
        <button @click="closeDialog" class="btn_1 full-width close-button">{{ $i18n('houses.show.close') }}</button>
      </div>
    </ul>
  </div>

  <div v-else>
    <ul class="bullets" v-show="facilities_available.length > 0">
      <h4 style="margin-bottom: 20px;">{{ $i18n('houses.show.facilities') }}</h4>
      <div class="row">
        <div v-for="facility in topSixFacilities" :key="facility.name" class="col-lg-6">
          <span v-html="formattedFacilityForTopSix(facility)"></span>
          <br>
        </div>
      </div>
      <br>

      <a href="#facilities-dialog" title="Facilities Details" class="facilities-details" @click="openDialog">{{ $i18n('houses.show.view_all_facilities') }}</a>

      <div id="facilities-dialog" class="zoom-anim-dialog mfp-hide">
        <h3 class="facilities-title">{{ $i18n('houses.show.facilities') }}</h3>
        <div v-for="type in Object.keys(filteredFacilities)" :key="type">
          <h6 style="margin-top: 40px;" 
              v-if="Object.keys(filteredFacilities[type]).length > 0">
            {{ $i18n("houses.facilities." + type.replace(/\s+/g, '')) }}
          </h6>
          <div class="row">
            <div v-for="facility in Object.keys(filteredFacilities[type])" :key="facility" class="col-lg-12">
              <span v-html="formattedFacility(type, facility)"></span>
              <br>
            </div>
          </div>
        </div>
        <button @click="closeDialog" class="btn_1 full-width close-button">{{ $i18n('houses.show.close') }}</button>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    facilities_available: Array,
    facilities_mapping: Object,
    room_amenities: Array,
    property_type: String,
  },
  data: function() {
    return {
      filteredFacilities: {},
      filteredAmenities: [],
      topSixFacilities: {},
      topSixAmenities: [],
    }
  },
  computed: {
    propertyTypeIsApartment() {
      return this.property_type == "apartment"
    }
  },
  methods: {
    formattedFacilityForTopSix: function(facility) {
      return `<i class="${facility.icon}"></i> ${I18n.t("houses.facilities." + facility.name)}`
    },
    formattedFacility: function(type, facility) {
      return `<i class="${this.facilities_mapping[type][facility].icon}"></i> ${I18n.t("houses.facilities." + facility)}`
    },
    formattedAmenity: function(amenity) {
      return `<i class="${this.$store.state.amenities_icons[amenity]}"></i> ${I18n.t("rooms.amenities." + amenity)}`;
    },
    openDialog() {
      event.preventDefault();
      $.magnificPopup.open({
        items: {
          src: '#facilities-dialog',
        },
        type: 'inline',
        fixedContentPos: true,
        fixedBgPos: true,
        overflowY: 'auto',
        closeBtnInside: true,
        closeOnBgClick: true,
        enableEscapeKey: true,
        preloader: false,
        midClick: true,
        removalDelay: 300,
        closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
        mainClass: 'my-mfp-zoom-in'
      });
    },
    closeDialog() {
      $.magnificPopup.close();
    }
  },
  mounted() {
    // filter facilities mapping to remove facilities not enabled
    let keys = Object.keys(this.facilities_mapping)
    for (let key of keys) {
      let facilities_not_available = Object.keys(this.facilities_mapping[key]).filter(
        facility => !this.facilities_available.includes(facility)
      )
      // delete facilities not available from the object
      for (let f of facilities_not_available) {
        delete this.facilities_mapping[key][f];
      }
    }
    this.filteredFacilities = this.facilities_mapping;

    // select top 6 facilities for summary
    if (this.facilities_available.length > 0) {
      let array = []
      for (let key of keys) {
        for (let f of this.facilities_available) {
          if (this.facilities_mapping[key][f]) {
            let hash = this.facilities_mapping[key][f]
            hash.name = f
            array.push(hash)
          }
        }
      }
      // sort according to weightage
      array = array.sort((a,b) => (parseInt(a.weightage) < parseInt(b.weightage)) ? 1 : ((parseInt(b.weightage) < parseInt(a.weightage)) ? -1 : 0));
      // slice the top 6
      this.topSixFacilities = array.slice(0, 6);
    }

    // IF PROPERTY TYPE IS APARTMENT
    // filter amenities without icons away
    if (this.propertyTypeIsApartment && this.room_amenities.length > 0) {
      let amenityIconsAvailable = Object.keys(this.$store.state.amenities_icons);
      for (let amenity of this.room_amenities) {
        if (amenityIconsAvailable.includes(amenity)) {
          this.filteredAmenities.push(amenity);
        }
      }
      // select top 6 amenities
      this.topSixAmenities = this.filteredAmenities.slice(0, 6);
    }
  }
}
</script>

<style lang="scss" scoped>
.facilities-details {
  margin-top: 10% !important;
  text-decoration: underline;
}
.facilities-title {
  padding-top: 40px;
}
.close-button{
  margin-top: 40px;
  color: #666;
  background-color: #e4e4e4;
  @media (min-width: 768px) {
    width: 40%;
    margin-left: 30%;
  }
  &:hover {
    background-color: var(--primary);
    color: white;
  }
}
</style>
