export function initialState() {
  return {
    availability: [],
    datepickerAvailability: [],
    rooms: [],
    ratePlanUID: 0,
    housePath: '',
    order:{
      line_items:[],
      starts_at: '',
      ends_at: '',
      item_count: 0,
      total: 0,
      currency: ''
    },
    summary: {},
    numberOfDays: 0,
    roomId: 0,
    ratePlanID: 0,
    adults: 1,
    onlyAdult: false,
    children: 0,
    roomBtnDisabled: {},
  }
}

export function orderModel() {
  return {
    line_items:[],
    starts_at: '',
    ends_at: '',
    item_count: 0,
    total: 0,
    currency: ''
  }
}
