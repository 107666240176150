<template>
  <div>
    <div class="truncated"
          v-bind:class="{ expand: isExpanded, isTruncated: !isExpanded, gray }" 
          v-bind:style="{'max-height': calculateMaxHeight}">
      <slot></slot>
    </div>
    <span class="truncated-read-more-toggle" 
          v-bind:style="{'margin-top': calculateMarginTop}" 
          v-on:click="toggleExpansion">{{ this.isExpanded ? $i18n("houses.index.read_more_close") : $i18n("houses.index.read_more") }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    truncLimit: {
      type: Number,
      default: 100
    },
    gray: {
      type: Boolean,
      default: false
    }
  },
  
  data: function() {
    return {
      isExpanded: false,
      maxHeight: this.truncLimit+'px',
    }
  },

  methods: {
    toggleExpansion: function() {
      this.isExpanded = !this.isExpanded;
    },
  },

  computed : {
    calculateMarginTop: function() {
      return this.truncLimit > 100 ? '0px' : '20px';
    },
    calculateMaxHeight: function() {
      return this.isExpanded ? 'calc(100%)' : this.maxHeight;
    }
  }

};
</script>

<style lang="scss" scoped>
.truncated-read-more-toggle {
  color: var(--primary);
  cursor: pointer;
  display: block;
  padding: 0 15px 5px 10px;
  text-align: right;
  position: relative;
}
</style>
